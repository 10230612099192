<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-form v-model="formValid" ref="confessionForm">
    <v-layout
      wrap
      justify-start
      v-for="(entry,index) in confessions"
      :key="entry._id"
      px-3 class="pb-4"
    >
      <!-- Left Side: Select Day -->

      <v-flex xs12 sm3 md3 lg3 pt-0 pr-1>
        <v-select
          outlined
          dense
         :rules="[rules.required]"
          class="text-field"
          :items="numOfWeek"
          item-value="value"
          item-text="name"
          v-model="entry.selectedWeek"
          placeholder="Select Week"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm3 md3 lg3 pt-0 pr-1 pt-2
      pt-lg-0
      pt-sm-0
      pt-md-0>
        <v-select
          outlined
          dense
         :rules="[rules.required]"
          class="text-field"
          :items="daysOfWeek"
          item-value="value"
          item-text="name"
          v-model="entry.selectedDay"
          placeholder="Select day"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm3 md3 lg3 pt-0 pr-1 pt-2
      pt-lg-0
      pt-sm-0
      pt-md-0>
        <v-menu
          v-model="entry.menu"
          :close-on-content-click="false"
          :nudge-bottom="40" 
          transition="slide-y-reverse-transition"
          width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="entry.formattedTime"
              outlined
             :rules="[rules.required]"
              dense
              class="text-field"
              placeholder="Start Time"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-card class="time-picker-card" width="auto">
            <v-card-text class="time-picker-card-text">
              <v-layout row wrap>
                <v-flex xs4>
                  <v-select
                    v-model="entry.selectedHour"
                    :items="hours"
                    label="Hour"
                    solo
                    dense
                    :hide-details="true"
                     :rules="[rules.required]"
                    class="time-picker-select"
                    @change="updateFormattedTime(entry)"
                  ></v-select>
                </v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="entry.selectedMinute"
                    :items="minutes"
                    label="Minute"
                    solo
                    dense
                    hide-details
                     :rules="[rules.required]"
                    class="time-picker-select"
                    @change="updateFormattedTime(entry)"
                  ></v-select>
                </v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="entry.selectedPeriod"
                    :items="periods"
                    label="AM/PM"
                    solo
                    dense
                    hide-details
                     :rules="[rules.required]"
                    class="time-picker-select"
                    @change="updateFormattedTime(entry)"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>

      <v-flex xs12 sm3 md3 lg3 pt-0 pr-1 pt-2
      pt-lg-0
      pt-sm-0
      pt-md-0>
        <v-menu
          v-model="entry.menu2"
          :close-on-content-click="false"
          :nudge-bottom="40"
          transition="slide-y-reverse-transition"
          width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="entry.formattedTime2"
              outlined
               :rules="[rules.required]"
              dense
              class="text-field"
              placeholder="End Time"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-card class="time-picker-card" width="auto">
            <v-card-text class="time-picker-card-text">
              <v-layout row wrap>
                <v-flex xs4>
                  <v-select
                    v-model="entry.endHour"
                    :items="hours"
                    label="Hour"
                    solo
                    dense
                    :hide-details="true"
                     :rules="[rules.required]"
                    class="time-picker-select"
                    @change="updateFormattedTime2(entry)"
                  ></v-select>
                </v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="entry.endMinute"
                    :items="minutes"
                    label="Minute"
                    solo
                    dense
                    hide-details
                    class="time-picker-select"
                    @change="updateFormattedTime2(entry)"
                  ></v-select>
                </v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="entry.endPeriod"
                    :items="periods"
                    label="AM/PM"
                    solo
                    dense
                    hide-details
                     :rules="[rules.required]"
                    class="time-picker-select"
                    @change="updateFormattedTime2(entry)"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>



      <!-- Right Side: Rite, Language, and Time -->

      <v-flex xs12 sm6 md3 lg3 pt-0 pr-1 pt-2
      pt-lg-2
      pt-sm-2
      pt-md-0>
        <v-select
          outlined
          dense
       :rules="[rules.required]"
          :items="riteArray"
          v-model="entry.rite"
          placeholder="Select Rite"
          class="text-field"
          item-text="name"
          item-value="_id"
        ></v-select>
      </v-flex>

      <v-flex xs12 sm5 md3 lg3 pt-0 pr-1 pt-2
      pt-lg-2
      pt-sm-2
      pt-md-0>
        <v-select
       :rules="[rules.required]"
          outlined
          dense
          :items="languageArray"
          item-text="languageName"
          item-value="_id"
          v-model="entry.language"
          placeholder="Select Language"
          class="text-field"
        ></v-select>
      </v-flex>

      <v-flex xs12 sm1 md1 lg1 pr-0 pt-2 
      pt-lg-0
      pt-sm-2
      pt-md-0>
        <v-btn class="ml-lg-5 ml-md-5 ml-sm-0 ml-0 mt-4"
          icon
          @click="removeEntry(index)"
          color="red"
          small
          v-if="confessions.length > 1"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-flex pl-3>
      <v-btn outlined color="blue darken-5" @click="addEntry" small
        ><v-icon> mdi-plus </v-icon>More</v-btn
      >
    </v-flex>

    <v-flex xs12 text-end pb-3>
      <v-btn
        color="blue darken-1"
            @click="submitForm"
        style="font-family: interregular; font-size: 15px; color: white"
        >Add Confession </v-btn
      >
    </v-flex>
    </v-form>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      formValid: false,
      rules: {
        required: (value) => !!value || 'This field is required',
      },
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 4,
      showSnackBar: false,
        timeout:5000,
        msg: null,
      deletedialog: false,
      dialogdate: false,
      OccasionDate: null,
      hours: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, "0")
      ),
      minutes: Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, "0")
      ),
      periods: ["AM", "PM"],
      // edit

      selectedDay: null,
      selectedWeek: null,

      menu2: false,
      menu1: false,
      isAddingcon: false,
      expandedconf: true,
      currentAddType: null,

      hoursArray: Array.from({ length: 12 }, (_, i) => String(i + 1)), // ['1', '2', ..., '12']
      minutesArray: Array.from({ length: 60 }, (_, i) =>
        String(i).padStart(2, "0")
      ),
      periodArray: ["AM", "PM"],

      //time: null,

      daysOfWeek: [
        { name: "SUN", value: "0" },
        { name: "MON", value: "1" },
        { name: "TUE", value: "2" },
        { name: "WED", value: "3" },
        { name: "THR", value: "4" },
        { name: "FRI", value: "5" },
        { name: "SAT", value: "6" },
      ],

      numOfWeek: [
        { name: "First Week", value: "1" },
        { name: "Second Week", value: "2" },
        { name: "Third Week", value: "3" },
        { name: "Fourth Week", value: "4" },
        { name: "Fifth Week", value: "5" },
      ],

      riteArray: [],
      languageArray: [],
      confessions: [
        {
          rite: "",
          language: "",
          selectedHour: "",
          selectedMinute: "",
          selectedPeriod: "",
          endHour: "",
          endMinute: "",
          endPeriod: "",
          selectedDay: "",
          selectedWeek: "",
          menu: false,
          menu2: false,
          OccasionType: "MonthlySpecial",
          // recurringMonthly: { weekOfMonth: "", dayOfWeek: "" },
        },
      ],
    };
  },
  watch: {
    daysOfWeek(newValue) {
      console.log("Days of Week:", newValue);
    },
  },
  computed: {
    formattedTime() {
    return `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`;
  },
  formattedTime2() {
    return `${this.endHour}:${this.endMinute} ${this.endPeriod}`;
  },
    // formattedDate() {
    //   // Only format and return the date if one is selected
    //   return this.OccasionDate
    //     ? new Date(this.OccasionDate).toLocaleDateString()
    //     : "";
    // },
  },
  mounted() {
    this.getLanguage();
    this.getRite();
    // this.getData();
    // this.setDefaultDay();
  },
  methods: {
    formatDate1(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB").replace(/\//g, "-");
    },
    // date
    // updateDate(OccasionDate) {
    //   this.OccasionDate = OccasionDate;
    //   this.dialogdate = false; // Close the dialog after selecting the date
    // },

    addEntry() {
      this.confessions.push({
        rite: "",
        language: "",
        selectedHour: "",
        selectedMinute: "",
        selectedPeriod: "",
        menu: false,
        formattedTime: "",

        endHour: "",
        endMinute: "",
        endPeriod: "",
        menu2: false,
        formattedTime2: "",

        selectedDay: "",
        selectedWeek: "",
        OccasionType: "MonthlySpecial",
      });
    },
    removeEntry(index) {
      this.confessions.splice(index, 1);
    },
    convertTo24HourFormat(hour, minute, period) {
  let convertedHour = parseInt(hour, 10);

  // Convert 12 AM to 00 (midnight) in 24-hour format
  if (period === "AM" && convertedHour === 12) {
    convertedHour = 0;
  } 
  // Convert 12-hour PM time to 24-hour format (except 12 PM)
  else if (period === "PM" && convertedHour !== 12) {
    convertedHour += 12; // Convert to 24-hour format for PM times
  }

  return `${convertedHour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
},
updateFormattedTime(entry) {
  // This sets the formatted time to display in 12-hour format with AM/PM
  entry.formattedTime = `${entry.selectedHour}:${entry.selectedMinute} ${entry.selectedPeriod}`;
  console.log(entry.formattedTime);
},

updateFormattedTime2(entry) {
  // This sets the formatted time to display in 12-hour format with AM/PM
  entry.formattedTime2 = `${entry.endHour}:${entry.endMinute} ${entry.endPeriod}`;
  console.log(entry.formattedTime2);
},

    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    updateDate(date) {
      this.menu = false; // Close the menu after selecting the date
      this.editDate = date;
      this.editingitem.occasionDate = this.parseDate(date);
    },
    formatDate(date) {
      if (!date) return "";
      // Ensure date is formatted to 'YYYY-MM-DD'
      return date instanceof Date ? date.toISOString().slice(0, 10) : date;
    },
    parseDate(dateString) {
      // Convert 'YYYY-MM-DD' format to a Date object
      return new Date(dateString).toISOString();
    },
    submitForm() {
      if (this.$refs.confessionForm.validate()) {
        this.itemadd(); // Your existing method for adding Holy Mass
      } else {
        this.msg = "Please correctly fill out all required fields";
        this.showSnackBar = true;
      }
    },
    itemadd() {
      const data = {
        // if(this.holyrite || this.ghh){
        //   dayOfWeek:this.holyrite
        // }
        // assuming you have parishId defined in your data
        parish: this.$route.query.id,

        confessions: this.confessions.map((entry) => ({
          OccasionType: entry.OccasionType,
          rite: entry.rite,
          language: entry.language,
          recurringMonthly:{weekOfMonth: entry.selectedWeek, dayOfWeek: entry.selectedDay},
          // startTime: entry.formattedTime, // Combine time
          startTime: this.convertTo24HourFormat(
            entry.selectedHour,
            entry.selectedMinute,
            entry.selectedPeriod
          ),
          endTime: this.convertTo24HourFormat(
            entry.endHour,
            entry.endMinute,
            entry.endPeriod
          ),
          // Include occasionType if applicable
        })),
      };

      axios({
        url: "/add/multiple/confession",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            // this.isAdding = !this.isAdding;
            // if (!this.isAdding) {
            //   this.currentAddType = null; // Reset the currentAddType when exiting add mode
            // }
            // this.expanded = true;
            this.$emit("dataAdded", { 
          ref: "addMonthly",
          getMonthlyData: true,
        });
        this.expandedconf = true;
        // this.isAdding = false;
        // window.location.reload();
            this.confessions = [
              {
                rite: "",
                language: "",
                selectedHour: "12",
                selectedMinute: "00",
                selectedPeriod: "AM",
                endHour: "12",
                endMinute: "00",
                endPeriod: "AM",
                menu2: false,
                menu: false,
                OccasionType: "MonthlySpecial",
                selectedDay: "",
                selectedWeek: "",
              },
            ];
            // Clear form fields or reset state
            this.selectedDay = null;
            
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // formatTimes(startTime) {
    //     return `${this.convertUTCToLocal(startTime)} `;
    //   },
    // formatTime(dateTime) {
    //   // Extract the time part from the string
    //   const timePart = dateTime.split("T")[1].split("Z")[0];

    //   // Split time into hours and minutes
    //   let [hours, minutes] = timePart.split(":");

    //   // Convert hours to a number
    //   hours = parseInt(hours);

    //   // Determine AM/PM
    //   const ampm = hours >= 12 ? "PM" : "AM";

    //   // Convert 24-hour time to 12-hour time
    //   hours = hours % 12;
    //   hours = hours ? hours : 12; // Adjust 0 to 12

    //   // Combine hours, minutes, and AM/PM
    //   const formattedTime = `${hours}:${minutes} ${ampm}`;

    //   return formattedTime;
    // },

    // toggleExpand() {
    //   this.expanded = !this.expanded;
    // },
    // toggleAddMode() {
    //   this.isAdding = !this.isAdding;
    //   if (!this.isAdding) {
    //     this.currentAddType = null; // Reset the currentAddType when exiting add mode
    //   }
    // },
    // startAdd(type) {
    //   this.currentAddType = type;
    //   this.isAdding = true;
    //   this.expanded = false; // Collapse details view when adding
    // },
    // setDefaultDay() {
    //   // Find the first day with holyMassEntries
    //   const daysWithMass = this.holyMassEntries.map((mass) => mass.dayOfWeek);
    //   const defaultDay = this.daysOfWeek.find((day) =>
    //     daysWithMass.includes(day.value)
    //   );

    //   // Set the first available day with holyMassEntries or default to the first day in the list
    //   this.selectedDay = defaultDay
    //     ? defaultDay.value
    //     : this.daysOfWeek[0].value;
    // },
    // handleDayClick(day) {
    //   this.selectedDay = day;
    //   // Logic for filtering holyMassEntries based on the selected day
    // },

    // openDeleteDialog(mass) {
    //   this.itemToDelete = mass;
    //   this.deletedialog = true;
    // },
    // confirmDelete() {
    //   if (this.itemToDelete) {
    //     this.deleteItem(this.itemToDelete);
    //   }
    //   this.deletedialog = false;
    // },

    // deleteItem(r) {
    //   var data = {};
    //   data["id"] = r._id;
    //   axios({
    //     url: "/delete/holymass",
    //     method: "POST",
    //     data: data,
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.delete = false;
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         this.msg = "Delete Sucessfully";
    //         this.showsnackbar = true;
    //         this.getData();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    // saveMass() {
    //   if (this.newMass.title) {
    //     this.holymass.push({ title: this.newMass.title });
    //     this.newMass.title = ''; // Clear the form
    //     this.isAdding = false; // Exit add mode
    //   }
    // }
  },
};
</script>

<style scoped>
.time-picker-card {
  max-width: 280px;
  /* Adjust card width */
}

.time-picker-card-text {
  padding: 8px;
  /* Adjust padding inside the card */
}

.time-picker-select {
  width: 100%;
  /* Ensure dropdowns fill column width */
}

.time-picker-card-actions {
  padding: 8px;
  /* Adjust padding in the actions area */
}

.v-menu__content {
  z-index: 1000;
  /* Ensure the menu appears on top */
}

.v-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-field {
  font-family: interregular;
  font-size: 14px;
  color: black;
}
</style>