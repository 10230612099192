<template>
  <div>
    <!-- <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar> -->
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-form v-model="formValid" ref="confessionForm">
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout class="pb-2"
          wrap
          justify-start
          align-center
          v-for="(entry,index) in confessions"
          :key="entry._id"
        >
          <v-flex xs11 sm4 md2 lg2 pr-2>
            <!-- <v-text-field
              class="text-field"
              placeholder="Select Date"
              outlined
              dense
              :hide-details="true"
              :value="formattedDate"
              @click="dialogdate = true"
              readonly
            ></v-text-field> -->
            <v-text-field
            class="text-field"
            placeholder="Select Date"
            outlined
            dense
              :rules="[rules.required]"
            :value="formatDate(entry.OccasionDate)"
            @click="dialogdate = true"
            readonly
          ></v-text-field>


            <v-dialog v-model="dialogdate" max-width="290">
              <!-- <v-date-picker
                v-model="entry.OccasionDate"
                @input="updateDate"
              ></v-date-picker> -->
              <v-date-picker
                v-model="entry.OccasionDate"
                  :min="nowDate"
                @input="updateDate(entry, entry.OccasionDate)"
              ></v-date-picker>

            </v-dialog>
          </v-flex>

          <v-flex xs11 sm4 md2 lg2 pr-2 pt-2
          pt-lg-0
          pt-sm-0
          pt-md-0>
            <v-menu
              v-model="entry.menu"
              :close-on-content-click="false"
              :nudge-bottom="40" :nudge-left="50"
              transition="slide-y-reverse-transition"
              width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="entry.formattedTime"
                     :rules="[rules.required]"
                  outlined
                  dense
                  class="text-field"
                  placeholder="Start Time"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card class="time-picker-card" width="auto">
                <v-card-text class="time-picker-card-text">
                  <v-layout row wrap>
                    <v-flex xs4>
                      <v-select
                        v-model="entry.selectedHour"
                        :items="hours"
                        label="Hour"
                        :hide-details="true"
                         :rules="[rules.required]"
                        solo
                        dense
                        class="time-picker-select"
                        @change="updateFormattedTime(entry)"
                      ></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-select
                        v-model="entry.selectedMinute"
                        :items="minutes"
                        label="Minute"
                        solo
                        dense
                         :rules="[rules.required]"
                        :hide-details="true"
                        class="time-picker-select"
                        @change="updateFormattedTime(entry)"
                      ></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-select
                        v-model="entry.selectedPeriod"
                        :items="periods"
                        label="AM/PM"
                        solo
                        dense
                         :rules="[rules.required]"
                        :hide-details="true"
                        class="time-picker-select"
                        @change="updateFormattedTime(entry)"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-flex>

          <v-flex xs11 sm4 md2 lg2 pr-2 pt-2
          pt-lg-0
          pt-sm-0
          pt-md-0>
            <v-menu
              v-model="entry.menu2"
              :close-on-content-click="false"
              :nudge-bottom="40" :nudge-left="50"
              transition="slide-y-reverse-transition"
              width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="entry.formattedTime2"
                    :rules="[rules.required]"
                  outlined
                  dense
                  class="text-field"
                  placeholder="End Time"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card class="time-picker-card" width="auto">
                <v-card-text class="time-picker-card-text">
                  <v-layout row wrap>
                    <v-flex xs4>
                      <v-select
                        v-model="entry.endHour"
                        :items="hours"
                        label="Hour"
                        :hide-details="true"
                         :rules="[rules.required]"
                        solo
                        dense
                        class="time-picker-select"
                        @change="updateFormattedTime2(entry)"
                      ></v-select>
                    </v-flex>
                    <v-flex xs4 >
                      <v-select
                        v-model="entry.endMinute"
                        :items="minutes"
                        label="Minute"
                        solo
                        dense
                        :hide-details="true"
                         :rules="[rules.required]"
                        class="time-picker-select"
                        @change="updateFormattedTime2(entry)"
                      ></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-select
                        v-model="entry.endPeriod"
                        :items="periods"
                        label="AM/PM"
                        solo
                        dense
                        :hide-details="true"
                         :rules="[rules.required]"
                        class="time-picker-select"
                        @change="updateFormattedTime2(entry)"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-flex>

          <!-- <v-flex xs3 pr-2>
            <v-text-field
              class="text-field"
              placeholder="Holy Mass name"
              outlined
              dense
              :hide-details="true"
              v-model="entry.massName"
            ></v-text-field>
          </v-flex> -->

          <v-flex xs11 sm6 md2 lg2 pr-2 pt-2
          pt-lg-0
          pt-sm-2
          pt-md-0>
            <v-select
              outlined
              dense
                 :rules="[rules.required]"
              :items="riteArray"
              v-model="entry.rite"
              placeholder="Select Rite"
              class="text-field"
              item-text="name"
              item-value="_id"
            ></v-select>
          </v-flex>

          <v-flex xs11 sm5 md3 lg3 pr-2 pt-2
          pt-lg-0
          pt-sm-2
          pt-md-0>
            <v-select
              outlined
              dense
                 :rules="[rules.required]"
              :items="languageArray"
              item-text="languageName"
              item-value="_id"
              v-model="entry.language"
              placeholder="Select Language"
              class="text-field"
            ></v-select>
          </v-flex>

          <v-flex xs1 sm1 md1 lg1 pr-0 pt-2
          pt-lg-0
          pt-sm-2
          pt-md-0>
            <v-btn class="ml-lg-5 ml-md-5 ml-sm-5 ml-0"
              icon
            
              @click="removeEntry(index)"
              color="red"
              small
              v-if="confessions.length > 1"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-flex pt-2>
      <v-btn outlined color="blue darken-5" @click="addEntry" small
        ><v-icon> mdi-plus </v-icon>More</v-btn
      >
    </v-flex>

    <v-flex xs12 text-end pb-3>
      <v-btn
        color="blue darken-1"
        @click="submitForm"
        style="font-family: interregular; font-size: 15px; color: white"
        >Add Confession</v-btn
      >
    </v-flex>
    </v-form>
  </div>
</template>




<script>
import axios from "axios";
//import AddSpecialMass from './addSpecialMass.vue';
export default {
  // components:{
  //   AddSpecialMass
  // },
  data() {
    return {
      formValid: false,
      rules: {
        required: (value) => !!value || 'This field is required',
      },
      showSnackBar: false,
        timeout:5000,
        msg: null,
      dialogdate: false,
      OccasionDate: "",
      //massName: "",
      hours: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, "0")
      ),
      minutes: Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, "0")
      ),
      periods: ["AM", "PM"],
      // edit

      // selectedDay: null,
      //holyMassEntries: [],

      menu2: false,
      isAddingcon: false,
      expanded: true,
      currentAddType: null,

      hoursArray: Array.from({ length: 12 }, (_, i) => String(i + 1)), // ['1', '2', ..., '12']
      minutesArray: Array.from({ length: 60 }, (_, i) =>
        String(i).padStart(2, "0")
      ),
      periodArray: ["AM", "PM"],

      riteArray: [],
      languageArray: [],
      confessions: [
        {
          rite: "",
          language: "",
          selectedHour: "",
          selectedMinute: "",
          selectedPeriod: "",
          OccasionDate: "",OccasionType: "Special",
          menu: false,
          menu2: false,

         endHour: "",
        endMinute: "",
        endPeriod: "",
        },
      ],
    };
  },

  computed: {
    formattedTime() {
    return `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`;
  },
  formattedTime2() {
    return `${this.endHours}:${this.endMinutes} ${this.endPeriod}`;
  },
    formattedDate() {
      // Only format and return the date if one is selected
      return this.OccasionDate
        ? new Date(this.OccasionDate).toLocaleDateString()
        : "";
    },
    nowDate() {
      const now = new Date();
      return now.toISOString().substr(0, 10);
    },
  },
  mounted() {
    this.getLanguage();
    this.getRite();
    //this.getData();
    // this.setDefaultDay();
  },
  methods: {
    // date
    updateDate(entry, date) {
    entry.OccasionDate = date;
    this.dialogdate = false; // Close the dialog after selecting the date
  },
    formatDate(date) {
    return date ? new Date(date).toLocaleDateString() : "";
  },

  addEntry() {
  this.confessions.push({
    rite: "",
    language: "",
    selectedHour: "",
    selectedMinute: "",
    selectedPeriod: "",
    menu: false,

    endHour: "",
    endMinute: "",
    endPeriod: "",
    menu2: false,


    OccasionDate: "",
    formattedTime: "",
    formattedTime2: "",
    formattedDate: "",
   
    OccasionType: "Special",
  });
},

    removeEntry(index) {
      this.confessions.splice(index, 1);
    },
    convertTo24HourFormat(hour, minute, period) {
  let convertedHour = parseInt(hour, 10);

  // Convert 12 AM to 00 (midnight) in 24-hour format
  if (period === "AM" && convertedHour === 12) {
    convertedHour = 0;
  } 
  // Convert 12-hour PM time to 24-hour format (except 12 PM)
  else if (period === "PM" && convertedHour !== 12) {
    convertedHour += 12; // Convert to 24-hour format for PM times
  }

  return `${convertedHour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
},
updateFormattedTime(entry) {
  // This sets the formatted time to display in 12-hour format with AM/PM
  entry.formattedTime = `${entry.selectedHour}:${entry.selectedMinute} ${entry.selectedPeriod}`;
  console.log(entry.formattedTime);
},

updateFormattedTime2(entry) {
  // This sets the formatted time to display in 12-hour format with AM/PM
  entry.formattedTime2 = `${entry.endHour}:${entry.endMinute} ${entry.endPeriod}`;
  console.log(entry.formattedTime2);
},
    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submitForm() {
      if (this.$refs.confessionForm.validate()) {
        this.itemadd(); // Your existing method for adding Holy Mass
      } else {
        this.msg = "Please correctly fill out all required fields";
        this.showSnackBar = true;
      }
    },

    itemadd() {
      const data = {
        parish: this.$route.query.id,
        confessions: this.confessions.map((entry) => ({
          rite: entry.rite,
          language: entry.language,
          
          OccasionDate: entry.OccasionDate,
          OccasionType: entry.OccasionType,
          startTime: this.convertTo24HourFormat(
        entry.selectedHour,
        entry.selectedMinute,
        entry.selectedPeriod
      ),
      endTime: this.convertTo24HourFormat(
        entry.endHour,
        entry.endMinute,
        entry.endPeriod
      ),
        })),
      };

      axios({
        url: "/add/multiple/confession",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;

            // Emit an event to notify parent component
            console.log("Emitting dataAdded event");
            this.$emit("dataAdded", {
              ref: "addSpecial",
              getSpecialData: true,
             expandedconf : true,
              
            });
            

            // Reset the form fields
            this.OccasionDate = null;
            this.confessions = [
              {
                rite: "",
                language: "",
                selectedHour: "12",
                selectedMinute: "00",
                selectedPeriod: "AM",

              endHour: "12",
             endMinute: "00",
              endPeriod: "AM",

                OccasionDate: "",
                menu: false,
                menu2: false,
                OccasionType: "Special",
              },
            ];
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // itemadd() {
    //   const data = {
    //     // if(this.holyrite || this.ghh){
    //     //   dayOfWeek:this.holyrite
    //     // }
    //     // assuming you have parishId defined in your data
    //     parish: this.$route.query.id,
    //     //dayOfWeek: this.selectedDay,
    //     holymass: this.holymass.map((entry) => ({
    //       rite: entry.rite,
    //       language: entry.language,
    //       massName: entry.massName,
    //       OccasionDate: entry.OccasionDate,
    //       OccasionType: entry.OccasionType,
    //       // startTime: entry.formattedTime, // Combine time
    //       startTime: this.convertTo24HourFormat(
    //         entry.selectedHour,
    //         entry.selectedMinute,
    //         entry.selectedPeriod
    //       ),
    //       // Include occasionType if applicable
    //     })),
    //   };

    //   axios({
    //     url: "/add/multiple/holymasses",
    //     method: "POST",
    //     data: data,
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         this.msg = "Added Successfully";
    //         this.showsnackbar = true;
    //         this.dialog = false;
    //         this.$emit("stepper", {
    //           ref: "addSpecial",
    //           getSpecialData: true,
    //         });
    //         // Clear form fields or reset state
    //         this.OccasionDate = null;
    //         this.holymass = [
    //           {
    //             rite: "",
    //             language: "",
    //             selectedHour: "12",
    //             selectedMinute: "00",
    //             selectedPeriod: "AM",
    //             OccasionDate: "",
    //             massName: "",
    //             menu: false,
    //             OccasionType: "Special",
    //           },
    //         ];

    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    // formatTimes(startTime) {
    //     return `${this.convertUTCToLocal(startTime)} `;
    //   },
    // formatTime(dateTime) {
    //   // Extract the time part from the string
    //   const timePart = dateTime.split("T")[1].split("Z")[0];

    //   // Split time into hours and minutes
    //   let [hours, minutes] = timePart.split(":");

    //   // Convert hours to a number
    //   hours = parseInt(hours);

    //   // Determine AM/PM
    //   const ampm = hours >= 12 ? "PM" : "AM";

    //   // Convert 24-hour time to 12-hour time
    //   hours = hours % 12;
    //   hours = hours ? hours : 12; // Adjust 0 to 12

    //   // Combine hours, minutes, and AM/PM
    //   const formattedTime = `${hours}:${minutes} ${ampm}`;

    //   return formattedTime;
    // },
  },
};
</script>

<style scoped>
.time-picker-card {
  max-width: 280px;
  /* Adjust card width */
}

.time-picker-card-text {
  padding: 8px;
  /* Adjust padding inside the card */
}

.time-picker-select {
  width: 100%;
  /* Ensure dropdowns fill column width */
}

.time-picker-card-actions {
  padding: 8px;
  /* Adjust padding in the actions area */
}

.v-menu__content {
  z-index: 1000;
  /* Ensure the menu appears on top */
}

.v-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-field {
  font-family: interregular;
  font-size: 14px;
  color: black;
}
</style>