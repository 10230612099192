<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-form v-model="formValid" ref="adorationForm">
      <!-- Content for normal Mass Form -->
      <v-layout wrap justify-center>
        
        <!-- Left Side: Select Day -->
        <v-flex xs12 sm2 md2 lg2 align-self-center pr-2>
          <v-select
            multiple
            outlined
            dense
            class="text-field"
            :items="daysOfWeek"
            item-value="value"
            item-text="name"
            v-model="selectedDay"
            placeholder="Select a day"
            :rules="[rules.required]"
          ></v-select>
        </v-flex>

        <!-- Vertical Divider -->
        <v-flex xs12 sm1 md1 lg1 d-flex align-center justify-center>
          <v-divider vertical></v-divider>
        </v-flex>

        <!-- Right Side: Rite, Language, and Time -->
        <v-flex xs12 sm8 md8 lg9>
          <v-layout wrap>
            <v-flex xs12>
              <v-layout  align-center v-for="(entry, index) in adorations" :key="entry._id" wrap>
                
                <!-- Rite -->
                <v-flex xs12 sm6 md6 lg6 pr-2>
                  <v-select
                    outlined
                    dense
                    :rules="[rules.required]"
                    :items="riteArray"
                    v-model="entry.rite"
                    placeholder="Select Rite"
                    class="text-field"
                    item-text="name"
                    item-value="_id"
                  ></v-select>
                </v-flex>

                <!-- Language -->
                <v-flex xs12 sm6 md5 lg5 pr-2>
                  <v-select
                    outlined
                    dense
                    :rules="[rules.required]"
                    :items="languageArray"
                    item-text="languageName"
                    item-value="_id"
                    v-model="entry.language"
                    placeholder="Select Language"
                    class="text-field"
                  ></v-select>
                </v-flex>

                <!-- Start Time -->
                <v-flex xs12 sm5 md6 lg5 pr-2>
                  <v-menu
                    v-model="entry.menu"
                    :close-on-content-click="false"
                    :nudge-bottom="40"  :nudge-left="5"
                    width="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="entry.formattedTime"
                        outlined
                        dense
                        class="text-field"
                        placeholder="Start Time"
                        :rules="[rules.required]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-card class="time-picker-card" width="auto">
                      <v-card-text class="time-picker-card-text">
                        <v-layout row wrap>
                          <v-flex xs4>
                            <v-select
                              v-model="entry.selectedHour"
                              :items="hours"
                              label="Hour"
                              solo
                              dense
                              hide-details
                              :rules="[rules.required]"
                              class="time-picker-select"
                              @change="updateFormattedTime(entry)"
                            ></v-select>
                          </v-flex>
                          <v-flex xs4>
                            <v-select
                              v-model="entry.selectedMinute"
                              :items="minutes"
                              label="Minute"
                              solo
                              dense
                              hide-details
                              :rules="[rules.required]"
                              class="time-picker-select"
                              @change="updateFormattedTime(entry)"
                            ></v-select>
                          </v-flex>
                          <v-flex xs4>
                            <v-select
                              v-model="entry.selectedPeriod"
                              :items="periods"
                              label="AM/PM"
                              solo
                              dense
                              hide-details
                              :rules="[rules.required]"
                              class="time-picker-select"
                              @change="updateFormattedTime(entry)"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-flex>

                <!-- End Time -->
                <v-flex xs11 sm7 md5 lg6 pr-2>
                  <v-menu
                    v-model="entry.menu2"
                    :close-on-content-click="false"
                    :nudge-bottom="40"  :nudge-left="5"
                    width="200"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="entry.formattedTime2"
                        outlined
                        dense
                        class="text-field"
                        placeholder="End Time"
                        :rules="[rules.required]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-card class="time-picker-card" width="auto">
                      <v-card-text class="time-picker-card-text">
                        <v-layout row wrap>
                          <v-flex xs4>
                            <v-select
                              v-model="entry.endHours"
                              :items="hours"
                              label="Hour"
                              solo
                              dense
                              hide-details
                              :rules="[rules.required]"
                              class="time-picker-select"
                              @change="updateFormattedTime2(entry)"
                            ></v-select>
                          </v-flex>
                          <v-flex xs4>
                            <v-select
                              v-model="entry.endMinutes"
                              :items="minutes"
                              label="Minute"
                              solo
                              dense
                              hide-details
                              class="time-picker-select"
                              @change="updateFormattedTime2(entry)"
                            ></v-select>
                          </v-flex>
                          <v-flex xs4>
                            <v-select
                              v-model="entry.endPeriod"
                              :items="periods"
                              label="AM/PM"
                              solo
                              dense
                              hide-details
                              :rules="[rules.required]"
                              class="time-picker-select"
                              @change="updateFormattedTime2(entry)"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-flex>

                <!-- Remove Button -->
                <v-flex xs1 pl-md-4 pl-sm-2>
                  <v-btn
                    icon
                    @click="removeEntry(index)"
                    color="red"
                    small
                    v-if="adorations.length > 1"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Add More Button -->
            <v-flex xs10 text-left pt-2>
              <v-btn outlined color="blue darken-5" @click="addEntry" small>
                <v-icon>mdi-plus</v-icon>More
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>

        <!-- Add Confession Button -->
        <v-flex xs12 text-right pb-3>
          <v-btn
            color="blue darken-1"
            @click="submitForm"
            style="font-family: interregular; font-size: 15px; color: white;"
          >
            Add Adoration
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    
  </div>
</template>




<script>
import axios from "axios";
//import AddSpecialMass from './addSpecialMass.vue';
export default {
  // components:{
  //   AddSpecialMass
  // },
  data() {
    return {
      formValid: false,
      rules: {
        required: (value) => !!value || 'This field is required',
      },
      
      appLoading: false,
    
      showSnackBar: false,
      timeout: 5000,
      msg: "",
      deletedialog: false,
     
     
      hours: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, "0")
      ),
      minutes: Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, "0")
      ),
      periods: ["AM", "PM"],
      
      menu2: false,
      menu: false,
      // isAddingcon: false,
      // expandedador: false,
      currentAddType: null,

      hoursArray: Array.from({ length: 12 }, (_, i) => String(i + 1)), // ['1', '2', ..., '12']
      minutesArray: Array.from({ length: 60 }, (_, i) =>
        String(i).padStart(2, "0")
      ),
      periodArray: ["AM", "PM"],
     
    
    
      daysOfWeek: [
        { name: "SUN", value: "0" },
        { name: "MON", value: "1" },
        { name: "TUE", value: "2" },
        { name: "WED", value: "3" },
        { name: "THR", value: "4" },
        { name: "FRI", value: "5" },
        { name: "SAT", value: "6" },
      ],
      selectedDay: [],

      riteArray: [],
      languageArray: [],
      adorations: [
        {
          rite: "",
          language: "",
          selectedHour: "",
          selectedMinute: "",
          selectedPeriod: "",
          menu: false,

          endHours: "",
          endMinutes: "",
          endPeriod: "",
          menu2: false,
        },
      ],
    };
  },

  computed: {
    formattedTime() {
      return `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`;
    },
    formattedTime2() {
      return `${this.endHours}:${this.endMinutes} ${this.endPeriod}`;
    },
  
  },
  mounted() {
    this.getLanguage();
    this.getRite();
  },
  methods: {
    

    addEntry() {
      this.adorations.push({
        rite: "",
        language: "",
        selectedHour: "",
        selectedMinute: "",
        selectedPeriod: "",
        menu: false,
        formattedTime: "",

        endHours: "",
        endMinutes: "",
        endPeriod: "",
        menu2: false,
        formattedTime2: "",
      });
    },

    removeEntry(index) {
      this.adorations.splice(index, 1);
    },

    convertTo24HourFormat(hour, minute, period) {
      let convertedHour = parseInt(hour, 10);

      // Convert 12 AM to 00 (midnight) in 24-hour format
      if (period === "AM" && convertedHour === 12) {
        convertedHour = 0;
      }
      // Convert 12-hour PM time to 24-hour format (except 12 PM)
      else if (period === "PM" && convertedHour !== 12) {
        convertedHour += 12; // Convert to 24-hour format for PM times
      }

      return `${convertedHour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    },

    updateFormattedTime(entry) {
      // This sets the formatted time to display in 12-hour format with AM/PM
      entry.formattedTime = `${entry.selectedHour}:${entry.selectedMinute} ${entry.selectedPeriod}`;
      console.log(entry.formattedTime);
    },

    updateFormattedTime2(entry) {
      // This sets the formatted time to display in 12-hour format with AM/PM
      entry.formattedTime2 = `${entry.endHours}:${entry.endMinutes} ${entry.endPeriod}`;
      console.log(entry.formattedTime2);
    },

 

    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submitForm() {
      if (this.$refs.adorationForm.validate()) {
        this.itemadd(); // Your existing method for adding Holy Mass
      } else {
        this.msg = "Please fill out all required fields";
        this.showSnackBar = true;
      }
    },
    itemadd() {
      const data = {
        parish: this.$route.query.id,
        daysOfWeek: this.selectedDay,

        adorations: this.adorations.map((entry) => ({
      
      
          rite: entry.rite,
          language: entry.language,
        
          startTime: this.convertTo24HourFormat(
            entry.selectedHour,
            entry.selectedMinute,
            entry.selectedPeriod
          ),
          endTime: this.convertTo24HourFormat(
            entry.endHours,
            entry.endMinutes,
            entry.endPeriod
          ),
          // Include OccasionType if applicable
        })),
      };

      axios({
        url: "/add/weekly/adoration",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.$emit("dataAdded", {
              ref: "addWeekly",
              getData: true,
             expandedador : true,
              
            });
          
            this.adorations = [
              {
                rite: "",
                language: "",
                selectedHour: "12",
                selectedMinute: "00",
                selectedPeriod: "AM",
                menu: false,
                endHours: "12",
                endMinutes: "00",
                endPeriod: "AM",
                menu2: false,
              },
              this.selectedDay = []
            ];
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // itemadd() {
    //   const data = {
    //     // if(this.holyrite || this.ghh){
    //     //   dayOfWeek:this.holyrite
    //     // }
    //     // assuming you have parishId defined in your data
    //     parish: this.$route.query.id,
    //     //dayOfWeek: this.selectedDay,
    //     adorations: this.adorations.map((entry) => ({
    //       rite: entry.rite,
    //       language: entry.language,
    //       massName: entry.massName,
    //       OccasionDate: entry.OccasionDate,
    //       OccasionType: entry.OccasionType,
    //       // startTime: entry.formattedTime, // Combine time
    //       startTime: this.convertTo24HourFormat(
    //         entry.selectedHour,
    //         entry.selectedMinute,
    //         entry.selectedPeriod
    //       ),
    //       // Include occasionType if applicable
    //     })),
    //   };

    //   axios({
    //     url: "/add/multiple/adorationses",
    //     method: "POST",
    //     data: data,
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         this.msg = "Added Successfully";
    //         this.showsnackbar = true;
    //         this.dialog = false;
    //         this.$emit("stepper", {
    //           ref: "addSpecial",
    //           getSpecialData: true,
    //         });
    //         // Clear form fields or reset state
    //         this.OccasionDate = null;
    //         this.adorations = [
    //           {
    //             rite: "",
    //             language: "",
    //             selectedHour: "12",
    //             selectedMinute: "00",
    //             selectedPeriod: "AM",
    //             OccasionDate: "",
    //             massName: "",
    //             menu: false,
    //             OccasionType: "Special",
    //           },
    //         ];

    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    // formatTimes(startTime) {
    //     return `${this.convertUTCToLocal(startTime)} `;
    //   },
    formatTime(dateTime) {
      // Extract the time part from the string
      const timePart = dateTime.split("T")[1].split("Z")[0];

      // Split time into hours and minutes
      let [hours, minutes] = timePart.split(":");

      // Convert hours to a number
      hours = parseInt(hours);

      // Determine AM/PM
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert 24-hour time to 12-hour time
      hours = hours % 12;
      hours = hours ? hours : 12; // Adjust 0 to 12

      // Combine hours, minutes, and AM/PM
      const formattedTime = `${hours}:${minutes} ${ampm}`;

      return formattedTime;
    },
  },
};
</script>

<style scoped>
.time-picker-card {
  max-width: 280px;
  /* Adjust card width */
}

.time-picker-card-text {
  padding: 8px;
  /* Adjust padding inside the card */
}

.time-picker-select {
  width: 100%;
  /* Ensure dropdowns fill column width */
}

.time-picker-card-actions {
  padding: 8px;
  /* Adjust padding in the actions area */
}

.v-menu__content {
  z-index: 1000;
  /* Ensure the menu appears on top */
}

.v-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.selected-btn {
  background-color: #37a0f4 !important;
  /* Change to desired background color */
  color: #ffffff;
  /* Change to desired text color */
}

.card-content {
  display: flex;
  align-items: center;
  width: 100%;
}

</style>