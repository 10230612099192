<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center py-3>
      <v-flex xs12>
        <!-- Card with Expandable Section or Add Form -->
        <v-card>
          <v-card-title>
            <v-flex xs11 sm11 md11 lg3>
              <span class="holymasshdes">
                {{
                  isAdding
                    ? currentAddType === "holy" ? "Add Holy Mass" : currentAddType === "weekly" ? "Add Regular Mass" : currentAddType === "special" ? "Add Special Mass" : "Add Monthly Mass" : "Holy Mass Timing"
                }}
              </span>
            </v-flex>

            <v-flex xs1 class="hidden-lg-and-up">
              <v-btn v-if="!isAdding" icon @click="toggleExpand">
                <v-icon>{{
                  expanded ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-flex>
            <v-layout wrap justify-center pl-lg-0 pl-md-0 pl-sm-6 pl-0>
              <!-- Button container with responsive design -->
              <v-flex xs12 sm12 md12 lg12 justify-end>
                <v-layout wrap justify-end class="hidden-xs-only">
                  <!-- Add Holy Mass Button -->
                  <v-flex pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn small v-if="!isAdding" color="blue" style="color: white" @click="startAdd('holy')"
                      class="mr-4">
                      Add Holy Mass
                    </v-btn>
                  </v-flex>

                    <!-- Add weekly Mass Button -->
                    <v-flex pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                      <v-btn small v-if="!isAdding" color="blue" style="color: white" @click="startAdd('weekly')"
                        class="mr-4">
                        Add Regular Mass
                      </v-btn>
                    </v-flex>

                  <!-- Add Special Mass Button -->
                  <v-flex pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn small v-if="!isAdding" color="blue" style="color: white" @click="startAdd('special')"
                      class="mr-4">
                      Add Special Mass
                    </v-btn>
                  </v-flex>

                  <!-- Add Monthly Mass Button -->
                  <v-flex pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn small v-if="!isAdding" color="blue" style="color: white" @click="startAdd('monthly')">
                      Add Monthly Mass
                    </v-btn>
                  </v-flex>

                  <!-- Cancel Button -->

                  <!-- Expand/Collapse Button -->
                  <v-flex xs1 pl-lg-6 pl-md-6 class="hidden-md-and-down">
                    <v-btn v-if="!isAdding" icon @click="toggleExpand">
                      <v-icon>{{
                        expanded ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon>
                    </v-btn>
                  </v-flex>

                  <v-flex xs10 text-end my-1>
                    <v-btn v-if="isAdding" color="red" icon @click="toggleAddMode">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center class="hidden-sm-and-up">
                  <!-- Add Holy Mass Button -->
                  <v-flex xs10 pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn small block v-if="!isAdding" color="blue" style="color: white" @click="startAdd('holy')"
                      class="mr-4">
                      Add Holy Mass
                    </v-btn>
                  </v-flex>

                    <!-- Add weekly Mass Button -->
                    <v-flex xs10 pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                      <v-btn small block v-if="!isAdding" color="blue" style="color: white" @click="startAdd('weekly')"
                        class="mr-4">
                        Add Regular Mass
                      </v-btn>
                    </v-flex>

                  <!-- Add Special Mass Button -->
                  <v-flex xs10 pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn small block v-if="!isAdding" color="blue" style="color: white" @click="startAdd('special')"
                      class="mr-4">
                      Add Special Mass
                    </v-btn>
                  </v-flex>

                  <!-- Add Monthly Mass Button -->
                  <v-flex xs10 pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn small block v-if="!isAdding" color="blue" style="color: white" @click="startAdd('monthly')">
                      Add Monthly Mass
                    </v-btn>
                  </v-flex>

                  <!-- Cancel Button -->

                  <!-- Expand/Collapse Button -->
                  <v-flex xs1 pl-lg-6 pl-md-6 class="hidden-md-and-down">
                    <v-btn v-if="!isAdding" icon @click="toggleExpand">
                      <v-icon>{{
                        expanded ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon>
                    </v-btn>
                  </v-flex>

                  <v-flex xs10 text-end my-1>
                    <v-btn v-if="isAdding" color="red" icon @click="toggleAddMode">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-title>

          <v-expand-transition>
            <v-card-text v-if="expanded && !isAdding">
              <v-flex>
                <v-card outlined elevation="0" class="pa-4 pt-4">
                  <v-layout wrap justify-start>
                    <v-flex xs12 mb-3 style="
                        font-family: intersemibold;
                        color: black;
                        font-size: 17px;
                      ">
                      Normal Holy Mass
                    </v-flex>
                    <v-layout wrap justify-start pl-3>


                      <v-flex xs4 sm2 md1 lg1 pb-3 v-for="day in daysOfWeek" :key="day.value">
                        <v-btn class="pl-3" elevation="0" :class="{
                          'weekday-btn': true,
                          'selected-btn': selectedDay === day.value,
                        }" @click="handleDayClick(day.value)">
                          {{ day.name }}
                        </v-btn>
                      </v-flex>

                      <!-- Conditional rendering based on holyMassEntries array -->

                      <v-flex xs12 pt-2 style="
                          font-family: intersemibold;
                          color: #0975cc;
                          font-size: 15px;
                        ">
                        Holy Mass Details
                      </v-flex>
                      <template v-if="holyMassEntries.length > 0">
                        <v-flex xs12 sm6 md4 lg4 v-for="mass in holyMassEntries" :key="mass._id">
                          <v-tooltip v-if="mass.holymassEvent && mass.holymassEvent.length > 0" bottom color="#37a0f4"  nudge-top="15px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-card v-bind="attrs" v-on="on" rounded="lg" flat color="#e5f5ff" class="ma-2" height="100px">
                                <v-card-text class="text-left" style="overflow-y: auto; white-space: nowrap;">
                                  <v-layout>
                                    <v-flex xs10 sm10 md10 lg10 style="color: black; font-family: interregular;">
                                      <span style="color: black">
                                        <v-icon size="18px" color="primary">mdi-clock</v-icon>
                                        {{ formatTime(mass.startTime) }}

                                      </span><br />
                                     <span style="color: black;">
                                        <v-icon size="18px" color="primary">mdi-information-outline</v-icon>
                                        {{ mass.rite.name }},
                                        {{ mass.language.languageName }}
                                      </span>
                                      <br/>
                                      <span style="color: black" v-if="mass.massLocation && mass.massLocation !== 'Parish Hall'">
                                        <v-icon size="20px" color="primary">mdi-map-marker</v-icon>
                                        {{ mass.massLocation }}
                                      
                                      </span>
                                    </v-flex>
                                    <v-flex xs2 sm2 md2 lg1>
                                      <v-icon
                                      v-if="mass.holymassEvent && mass.holymassEvent.length > 0"
                                      class="position-absolute"
                                      bottom
                                      right size="21px"
                                      color="primary"
                                    >
                                    mdi-shield-cross
                                    </v-icon> 
                                      
                                      <!-- <img v-if="mass.holymassEvent && mass.holymassEvent.length > 0"
                                        src="@/assets/images/eventb.png" width="25px" height="25px" /> -->
                                    </v-flex>
                                    <v-flex xs1 class="text-right" align-self-center>
                                      <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon size="20px" v-bind="attrs" v-on="on"
                                            color="black">mdi-dots-vertical</v-icon>
                                        </template>
                                        <v-list>
                                          <v-list-item @click="editSlider(mass)">
                                            <v-icon small color="primary">mdi-pencil</v-icon>
                                            <v-list-item-content class="pl-3 addChurch">Edit</v-list-item-content>
                                          </v-list-item>
                                          <v-list-item @click="openDeleteDialog(mass)">
                                            <v-icon color="red" small>mdi-delete</v-icon>
                                            <v-list-item-content class="pl-3 addChurch">Delete</v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-flex>
                                  </v-layout>
                                </v-card-text>
                              </v-card>
                            </template>
                            <span style="color: white; font-family:interregular">{{ mass.holymassEvent.join(', ') }}</span>
                          </v-tooltip>

                          <!-- If no events, just show the card without tooltip -->
                          <v-card v-else rounded="lg" flat color="#e5f5ff" class="ma-2" height="100px">
                            <v-card-text class="text-left" style="overflow-y: auto; white-space: nowrap;">
                              <v-layout>
                                <v-flex xs11 style="color: black; font-family: interregular;">
                                <span style="color: black">
                                    <v-icon size="18px" color="primary">mdi-clock</v-icon>
                                    {{ formatTime(mass.startTime) }}
                                  </span><br />
                                  <span style="color: black;">
                                    <v-icon size="18px" color="primary">mdi-information-outline</v-icon>
                                    {{ mass.rite.name }},
                                    {{ mass.language.languageName }}
                                  </span>
                                  <br/>
                                  <span style="color: black" v-if="mass.massLocation && mass.massLocation !== 'Parish Hall'">
                                    <v-icon size="20px" color="primary">mdi-map-marker</v-icon>
                                    {{ mass.massLocation }}
                                  
                                  </span>
                                </v-flex>
                                <v-flex xs1 class="text-right" align-self-center>
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon size="20px" v-bind="attrs" v-on="on"
                                        color="black">mdi-dots-vertical</v-icon>
                                    </template>
                                    <v-list>
                                      <v-list-item @click="editSlider(mass)">
                                        <v-icon small color="primary">mdi-pencil</v-icon>
                                        <v-list-item-content class="pl-3 addChurch">Edit</v-list-item-content>
                                      </v-list-item>
                                      <v-list-item @click="openDeleteDialog(mass)">
                                        <v-icon color="red" small>mdi-delete</v-icon>
                                        <v-list-item-content class="pl-3 addChurch">Delete</v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </template>


                      <!-- Show this if holyMassEntries array is empty -->
                      <template v-else>
                        <v-flex xs12 text-center py-5 style="
                            font-family: intersemibold;
                            color: grey;
                            font-size: 13px;
                          ">
                          No Holy Mass Available
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex pt-5 v-if="specialMassEntries">
                <v-card outlined elevation="0" class="pa-4 pt-4">
                  <v-layout wrap justify-start>
                    <v-flex xs12 mb-3 style="
                        font-family: intersemibold;
                        color: black;
                        font-size: 17px;
                      ">
                      Special Day - Holy Mass
                    </v-flex>
                    <v-layout wrap justify-start pl-3>
                      <!-- Conditional rendering based on holyMassEntries array -->

                      <v-flex xs12 style="
                          font-family: intersemibold;
                          color: #0975cc;
                          font-size: 15px;
                        ">
                        Holy Mass Details
                      </v-flex>
                      <template v-if="specialMassEntries.length > 0">
                        <v-flex pt-2 pr-2 xs12 sm6 md4 lg4 v-for="mass in specialMassEntries" :key="mass._id">
                          <v-tooltip v-if="mass.holymassEvent && mass.holymassEvent.length > 0" color="#37a0f4" bottom nudge-top="15px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-card v-bind="attrs" v-on="on" rounded="lg" flat color="#e5f5ff" class="ma-2" height="120px">
                                <v-card-text class="text-left" style="overflow-y: auto; white-space: nowrap;">
                                  <v-layout>
                                    <v-flex xs10 sm10 md10 lg10 style="
                                    color: black;
                                    font-family: intersemibold;
                                    text-transform: capitalize;
                                  ">
                                      {{ mass.massName }}
                                    </v-flex>
                                    <v-flex xs2 sm2 md2 lg1>
                                      <!-- <img v-if="mass.holymassEvent && mass.holymassEvent.length > 0"
                                        src="@/assets/images/eventb.png" width="25px" height="25px" /> -->
                                        
                                       <v-icon
                                      v-if="mass.holymassEvent && mass.holymassEvent.length > 0"
                                      class="position-absolute"
                                      bottom
                                      right size="21px"
                                      color="primary"
                                    >
                                    mdi-shield-cross
                                    </v-icon> 
                                    </v-flex>
                                    <v-flex xs1 class="text-right">
                                      <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon size="20px" v-bind="attrs" v-on="on"
                                            color="black">mdi-dots-vertical</v-icon>
                                        </template>
                                        <v-list>
                                          <v-list-item @click="editSliderSpecial(mass)">
                                            <v-icon small color="primary">mdi-pencil</v-icon>
                                            <v-list-item-content class="pl-3 addChurch">Edit</v-list-item-content>
                                          </v-list-item>
                                          <v-list-item @click="openDeleteDialog(mass)">
                                            <v-icon color="red" small>mdi-delete</v-icon>
                                            <v-list-item-content class="pl-3 addChurch">Delete</v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-flex>
                                  </v-layout>

                                  <v-flex style="color: black; font-family: interregular">
                                    <span>
                                      <v-icon size="15px" color="primary">mdi-calendar</v-icon>
                                      {{ formatDate1(mass.occasionDate) }}
                                    </span>
                                    &nbsp;
                                    <span style="
                                    color: black;
                                    font-family: interregular;
                                  ">
                                      <v-icon size="15px" color="primary">mdi-clock</v-icon>
                                      {{ formatTime(mass.startTime) }}
                                    </span>
                                  </v-flex>

                                  <v-flex style="color: black; font-family: interregular">
                                    <span>
                                      <v-icon size="18px" color="primary">mdi-information-outline</v-icon>
                                      {{ mass.rite.name }},
                                      {{ mass.language.languageName }}
                                    </span>
                                    <br/>
                                    <span style="color: black" v-if="mass.massLocation && mass.massLocation !== 'Parish Hall'">
                                        <v-icon size="20px" color="primary">mdi-map-marker</v-icon>
                                        {{ mass.massLocation }}
                                      
                                      </span>
                                  </v-flex>
                                </v-card-text>
                              </v-card>
                            </template>
                            <span style="color: white; font-family:interregular">{{ mass.holymassEvent.join(', ') }}</span>
                          </v-tooltip>
                          <v-card v-else rounded="lg" flat  color="#e5f5ff" class="ma-2" height="120px">
                            <v-card-text class="text-left" style="overflow-y: auto; white-space: nowrap;">
                              <v-layout>
                                <v-flex xs11 style="
                                color: black;
                                font-family: intersemibold;
                                text-transform: capitalize;
                              ">
                                  {{ mass.massName }}
                                </v-flex>
                               
                                <v-flex xs1 class="text-right">
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon size="20px" v-bind="attrs" v-on="on"
                                        color="black">mdi-dots-vertical</v-icon>
                                    </template>
                                    <v-list>
                                      <v-list-item @click="editSliderSpecial(mass)">
                                        <v-icon small color="primary">mdi-pencil</v-icon>
                                        <v-list-item-content class="pl-3 addChurch">Edit</v-list-item-content>
                                      </v-list-item>
                                      <v-list-item @click="openDeleteDialog(mass)">
                                        <v-icon color="red" small>mdi-delete</v-icon>
                                        <v-list-item-content class="pl-3 addChurch">Delete</v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
                              </v-layout>

                              <v-flex style="color: black; font-family: interregular">
                                <span>
                                  <v-icon size="15px" color="primary">mdi-calendar</v-icon>
                                  {{ formatDate1(mass.occasionDate) }}
                                </span>
                                &nbsp;
                                <span style="
                                color: black;
                                font-family: interregular;
                              ">
                                  <v-icon size="15px" color="primary">mdi-clock</v-icon>
                                  {{ formatTime(mass.startTime) }}
                                </span>
                              </v-flex>

                              <v-flex style="color: black; font-family: interregular">
                                <span>
                                  <v-icon size="18px" color="primary">mdi-information-outline</v-icon>
                                  {{ mass.rite.name }},
                                  {{ mass.language.languageName }}
                                </span>
                                <br/>
                                <span style="color: black" v-if="mass.massLocation && mass.massLocation !== 'Parish Hall'">
                                  <v-icon size="20px" color="primary">mdi-map-marker</v-icon>
                                  {{ mass.massLocation }}
                                
                                </span>
                              </v-flex>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                        <!-- edit special mass -->
                      </template>

                      <!-- Show this if holyMassEntries array is empty -->
                      <template v-else>
                        <v-flex xs12 text-center py-5 style="
                            font-family: intersemibold;
                            color: grey;
                            font-size: 13px;
                          ">
                          No Holy Mass Available
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-layout>

                  <v-layout wrap justify-center pt-2 v-if="specialMassEntries">
                    <v-flex xs12 v-if="specialMassEntries.length > 0">
                      <v-pagination size="small" color="primary white--text" v-model="page" :length="Pagelength" circle>
                      </v-pagination>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex pt-5>
                <v-card outlined elevation="0" class="pa-4 pt-4">
                  <v-layout wrap justify-start>
                    <v-flex xs12 mb-3 style="
                        font-family: intersemibold;
                        color: black;
                        font-size: 17px;
                      ">
                      Monthly Holy Mass
                    </v-flex>
                    <v-layout wrap justify-start pl-3>
                      <v-flex class="hidden-xs-only" xs6 sm3 md2 lg2 pb-2 v-for="(day, index) in numOfWeek"
                        :key="day.value + '-' + index">
                        <v-btn elevation="0" size="30px" :class="{
                          'weekday-btn': true,
                          'selected-btn': selectedWeek === day.value,
                        }" @click="handleWeekClick(day.value)">
                          {{ day.name }}
                        </v-btn>
                      </v-flex>

                      <v-flex class="hidden-sm-and-up" xs6 sm3 md2 lg2 pt-2 v-for="day in numOfWeek" :key="day.value">
                        <v-btn elevation="1" small size="30px" :class="{
                          'weekday-btn': true,
                          'selected-btn': selectedWeek === day.value,
                        }" @click="handleWeekClick(day.value)">
                          {{ day.name }}
                        </v-btn>
                      </v-flex>

                      <!-- Conditional rendering based on holyMassEntries array -->

                      <v-flex xs12 pt-3 style="
                          font-family: intersemibold;
                          color: #0975cc;
                          font-size: 15px;
                        ">
                        Holy Mass Details
                      </v-flex>
                      <template v-if="monthlyMassEntries.length > 0">
                        <v-flex pt-2 pr-2 xs12 sm6 md4 lg4 v-for="mass in monthlyMassEntries" :key="mass._id">
                          <v-tooltip v-if="mass.holymassEvent && mass.holymassEvent.length > 0" color="#37a0f4" bottom nudge-top="15px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-card v-bind="attrs" v-on="on" rounded="lg" flat color="#e5f5ff" class="ma-2" height="100px">
                                <v-card-text class="text-left" style="overflow-y: auto; white-space: nowrap;">
                                  <v-layout>
                                    <v-flex  xs10 sm10 md10 lg10  style="
                                    color: black;
                                    font-family: interregular;
                                  ">
                                      <span style="
                                      color: black;
                                      font-family: interregular;
                                    ">
                                        <v-icon size="17px" color="primary">mdi-white-balance-sunny</v-icon>
                                        {{
                                          getDayName(
                                            mass.recurringMonthly.dayOfWeek
                                          )
                                        }}
                                      </span>
                                      <span style="
                                      color: black;
                                      font-family: interregular;
                                    ">
                                        <v-icon size="15px" color="primary">mdi-clock</v-icon>
                                        {{ formatTime(mass.startTime) }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs2 sm2 md2 lg1>
                                      <!-- <img v-if="mass.holymassEvent && mass.holymassEvent.length > 0"
                                        src="@/assets/images/eventb.png" width="25px" height="25px" /> -->
                                        <v-icon
                                      v-if="mass.holymassEvent && mass.holymassEvent.length > 0"
                                      class="position-absolute"
                                      bottom
                                      right size="21px"
                                      color="primary"
                                    >
                                    mdi-shield-cross
                                    </v-icon> 
                                    </v-flex>
                                    <v-flex xs1 class="text-right">
                                      <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon size="20px" v-bind="attrs" v-on="on"
                                            color="black">mdi-dots-vertical</v-icon>
                                        </template>
                                        <v-list>
                                          <v-list-item @click="editSliderMonthly(mass)">
                                            <v-icon small color="primary">mdi-pencil</v-icon>
                                            <v-list-item-content class="pl-3 addChurch">Edit</v-list-item-content>
                                          </v-list-item>
                                          <v-list-item @click="openDeleteDialog(mass)">
                                            <v-icon color="red" small>mdi-delete</v-icon>
                                            <v-list-item-content class="pl-3 addChurch">Delete</v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-flex>
                                  </v-layout>

                                  <v-flex style="color: black; font-family: interregular">
                                    <span>
                                      <v-icon size="18px" color="primary">mdi-information-outline</v-icon>
                                      {{ mass.rite.name }},
                                      {{ mass.language.languageName }}
                                    </span>
                                    <br/>
                                    <span style="color: black" v-if="mass.massLocation && mass.massLocation !== 'Parish Hall'">
                                        <v-icon size="20px" color="primary">mdi-map-marker</v-icon>
                                        {{ mass.massLocation }}
                                      
                                      </span>
                                  </v-flex>
                                </v-card-text>
                              </v-card>
                            </template>
                            <span style="color: white; font-family:interregular">{{ mass.holymassEvent.join(', ') }}</span>
                          </v-tooltip>
                          <v-card v-else rounded="lg" flat color="#e5f5ff" class="ma-2" height="100px">
                            <v-card-text class="text-left" style="overflow-y: auto; white-space: nowrap;">
                              <v-layout>
                                <v-flex xs11 style="
                                color: black;
                                font-family: interregular;
                              ">
                                  <span style="
                                  color: black;
                                  font-family: interregular;
                                ">
                                    <v-icon size="17px" color="primary">mdi-white-balance-sunny</v-icon>
                                    {{
                                      getDayName(
                                        mass.recurringMonthly.dayOfWeek
                                      )
                                    }}
                                  </span>
                                  <span style="
                                  color: black;
                                  font-family: interregular;
                                ">
                                    <v-icon size="15px" color="primary">mdi-clock</v-icon>
                                    {{ formatTime(mass.startTime) }}
                                  </span>
                                </v-flex>
                                <v-flex xs1 class="text-right">
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon size="20px" v-bind="attrs" v-on="on"
                                        color="black">mdi-dots-vertical</v-icon>
                                    </template>
                                    <v-list>
                                      <v-list-item @click="editSliderMonthly(mass)">
                                        <v-icon small color="primary">mdi-pencil</v-icon>
                                        <v-list-item-content class="pl-3 addChurch">Edit</v-list-item-content>
                                      </v-list-item>
                                      <v-list-item @click="openDeleteDialog(mass)">
                                        <v-icon color="red" small>mdi-delete</v-icon>
                                        <v-list-item-content class="pl-3 addChurch">Delete</v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
                              </v-layout>

                              <v-flex style="color: black; font-family: interregular">
                                <span>
                                  <v-icon size="18px" color="primary">mdi-information-outline</v-icon>
                                  {{ mass.rite.name }},
                                  {{ mass.language.languageName }}
                                </span>
                                <br/>
                                <span style="color: black" v-if="mass.massLocation && mass.massLocation !== 'Parish Hall'">
                                  <v-icon size="20px" color="primary">mdi-map-marker</v-icon>
                                  {{ mass.massLocation }}
                                
                                </span>
                              </v-flex>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </template>

                      <!-- Show this if holyMassEntries array is empty -->
                      <template v-else>
                        <v-flex xs12 text-center py-5 style="
                            font-family: intersemibold;
                            color: grey;
                            font-size: 13px;
                          ">
                          No Holy Mass Available
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-layout>
                </v-card>
                <!-- edit mass -->
              </v-flex>
            </v-card-text>
          </v-expand-transition>

          <v-layout wrap justify-center>
            <!-- Add Holy Mass Form -->
            <v-card-text v-if="isAdding">
              <!-- Content for add normal Mass Form -->
              <v-form v-model="formValid" ref="holyMassForm">
                <v-flex xs12 sm12 md12 lg12 pl-lg-10 pl-md-10 pl-sm-6 pl-0>
                  <v-layout align-center wrap justify-start pt-0 v-if="currentAddType === 'holy'">
                    <v-flex xs11 sm2 md2 lg2 pt-0>
                      <v-select outlined dense class="text-field" :items="daysOfWeek" item-value="value"
                        item-text="name" v-model="selectedDays" placeholder="Select a day"
                        :rules="[rules.required]"></v-select>
                    </v-flex>

                    <!-- Vertical Divider -->
                    <v-divider vertical class="mx-4"></v-divider>

                    <!-- Right Side: Rite, Language, and Time -->
                    <v-flex xs12 sm9 md9 lg9>
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-layout class="pb-2" wrap align-center v-for="(entry, index) in holymass" :key="entry._id">
                            <v-flex xs11 sm12 md3 lg4 pr-0 pr-lg-2 pr-md-2 pr-sm-1 pt-2 pt-lg-0 pt-sm-2 pt-md-0>
                              <v-select outlined dense :items="riteArray" v-model="entry.rite" placeholder="Select Rite"
                                class="text-field" item-text="name" item-value="_id"
                                :rules="[rules.required]"></v-select>
                            </v-flex>

                            <v-flex xs11 sm12 md4 lg4 pr-lg-2 pr-md-2 pr-sm-1 pt-2 pt-lg-0 pt-sm-2 pt-md-0>
                              <v-select outlined dense :items="languageArray" item-text="languageName" item-value="_id"
                                v-model="entry.language" placeholder="Select Language" class="text-field"
                                :rules="[rules.required]"></v-select>
                            </v-flex>

                            <v-flex xs11 sm12 md4 lg4 pr-lg-2 pr-md-0 pr-sm-1>
                              <v-menu v-model="entry.menu" :close-on-content-click="false" :nudge-bottom="40"
                                transition="slide-y-reverse-transition" width="200">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="entry.formattedTime" outlined dense
                                    class="text-field pt-sm-2 pt-lg-0 pt-md-0 pt-2" placeholder="Select Time" readonly
                                    v-bind="attrs" v-on="on" :rules="[rules.required]"></v-text-field>
                                </template>
                                <v-card class="time-picker-card" width="auto">
                                  <v-card-text class="time-picker-card-text">
                                    <v-layout row wrap>
                                      <v-flex xs4>
                                        <v-select v-model="entry.selectedHour" :items="hours" label="Hour" solo dense
                                          hide-details class="time-picker-select" @change="updateFormattedTime(entry)"
                                          @input="updateFormattedTime(entry)" :rules="[rules.required]"></v-select>
                                      </v-flex>
                                      <v-flex xs4>
                                        <v-select v-model="entry.selectedMinute" :items="minutes" label="Minute" solo
                                          dense hide-details class="time-picker-select"
                                          @change="updateFormattedTime(entry)" @input="updateFormattedTime(entry)"
                                          :rules="[rules.required]"></v-select>
                                      </v-flex>
                                      <v-flex xs4>
                                        <v-select v-model="entry.selectedPeriod" :items="periods" label="AM/PM" solo
                                          dense hide-details class="time-picker-select"
                                          @change="updateFormattedTime(entry)" @input="updateFormattedTime(entry)"
                                          :rules="[rules.required]"></v-select>
                                      </v-flex>
                                    </v-layout>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </v-flex>

                            <v-flex xs11 sm12 md4 lg4 pr-lg-2 pr-md-2 pr-sm-1 pt-2 pt-lg-0 pt-sm-2 pt-md-0>
                              <v-text-field v-model="entry.location" outlined dense
                              class="text-field pt-sm-2 pt-lg-0 pt-md-0 pt-2" placeholder="Enter Location"
                             ></v-text-field>
                            </v-flex>



                            <v-flex xs10 pl-3 pb-2>
                              <v-layout row align-center>
                                <span class="live-des mr-2">
                                  Is a special event held after Holy Mass?
                                </span>
                                <v-checkbox v-model="entry.isSpecialEventAfterMass" color="primary" hide-details
                                  class="mt-0"></v-checkbox>
                              </v-layout>
                            </v-flex>

                            <!-- Conditional v-select -->
                            <v-flex v-if="entry.isSpecialEventAfterMass" xs10 sm11 md11 lg11 pr-lg-2 pr-md-2 pr-sm-1 pt-2
                              pt-lg-2 pt-sm-2 pt-md-2>
                              <!-- <v-select class="pt-2 text-des" style="font-size: 14px" :rules="eventRules" outlined dense
                                :items="eventArray" v-model="entry.holymassEvent" item-text="name" item-value="name"
                                placeholder="Select Events" multiple></v-select> -->

                             <v-autocomplete class="pt-2 text-des" style="font-size: 14px"  outlined dense
                                :items="eventArray" v-model="entry.holymassEvent" item-text="name" item-value="name"
                                placeholder="Select Events" multiple  :search-input.sync="search1"></v-autocomplete> 

                            </v-flex>





                            <v-flex pl-lg-3 pl-sm-3 pl-md-3 pl-0>
                              <v-btn icon @click="removeEntry(index)" color="red" small v-if="holymass.length > 1">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex pt-2>
                          <v-btn outlined color="blue darken-5" @click="addEntry" small>
                            <v-icon> mdi-plus </v-icon>More
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 text-end pb-3>
                      <v-btn color="blue darken-1" @click="submitForm"
                        style="font-family: interregular; font-size: 15px; color: white;">
                        Add Holy Mass
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-form>

              <v-layout align-center wrap justify-center pt-0 v-if="currentAddType === 'weekly'">
                <AddWeeklyMass @stepper="winStepper" @dataAdded="handleDataAdded" />
              </v-layout>

              <v-layout align-center wrap justify-center pt-0 v-if="currentAddType === 'special'">
                <AddSpecialMass @stepper="winStepper" @dataAdded="handleDataAdded" />
              </v-layout>

              <v-layout align-center wrap justify-center pt-0 v-if="currentAddType === 'monthly'">
                <AddMonthlyHolyMass @stepper="winStepper" @dataAdded="handleDataAdded" />
              </v-layout>
            </v-card-text>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="editdialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT HOLY MASS </span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start px-2>
              <v-flex xs12 sm6  md6 lg6 pr-2>
                <span class="label">Day</span>
                <v-select outlined dense hide-details class="pt-2 text-des" :items="daysOfWeek" item-value="value"
                  item-text="name" v-model="editingitem.daysOfWeek" placeholder="Select a Day"></v-select>
              </v-flex>

              <v-flex xs12 sm6  md6 lg6 pr-2>
                <span class="label"> Rite </span>
                <v-select class="pt-2 text-des" style="font-size: 14px" outlined dense :items="riteArray"
                  v-model="editingitem.rite" item-text="name" item-value="_id"></v-select>
              </v-flex>
              <v-flex xs12 sm6  md6 lg6 pr-2>
                <span class="label"> Language </span>
                <v-select class="pt-2 text-des" style="font-size: 14px" outlined dense :items="languageArray"
                  item-text="languageName" item-value="_id" v-model="editingitem.language"></v-select>
              </v-flex>

              <v-flex xs12 sm6  md6 lg6 pr-2>
                <span class="label">Start Time</span>
                <v-layout>
                  <v-select outlined dense hide-details class="pt-2 text-des pr-2" :items="hoursArray"
                    v-model="editingitem.startHours" placeholder="Hours"></v-select>
                  <v-select outlined dense hide-details class="pt-2 text-des pr-2" :items="minutesArray"
                    v-model="editingitem.startMinutes" placeholder="Minutes"></v-select>
                  <v-select outlined dense hide-details class="pt-2 text-des" :items="periodArray"
                    v-model="editingitem.startPeriod" placeholder="AM/PM"></v-select>
                </v-layout>
              </v-flex>
              

              <v-flex xs12 sm12  md6 lg6 pt-2 pr-2 >
                <span class="label" >Location</span>
                <v-text-field v-model="displayedLocation" outlined dense
                class="text-des pt-sm-2 pt-lg-2 pt-md-2 pt-2" placeholder="Enter Location"
               ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12  md6 lg6 pt-2 pr-2>
                <span class="label">Special Events</span>
                <v-select class="text-des pt-2" style="font-size: 14px" outlined dense :items="eventArray"
                  v-model="editingitem.holymassEvent" item-text="name" item-value="name" placeholder="Select Events"
                  multiple></v-select>
              </v-flex>

            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="editdialog = false"><span style="color: black">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="edit(editingitem._id)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editspecialdialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT SPECIAL HOLY MASS </span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start px-2>
              <v-flex xs12 sm6 md6 lg6 pr-2>
                <span class="label"> Rite </span>
                <v-select class="pt-2 text-des" style="font-size: 14px" outlined dense :items="riteArray"
                  v-model="editingitem.rite" item-text="name" item-value="_id"></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6 lg6 pr-2>
                <span class="label"> Language </span>
                <v-select class="pt-2 text-des" style="font-size: 14px" outlined dense :items="languageArray"
                  item-text="languageName" item-value="_id" v-model="editingitem.language"></v-select>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12>
                <span class="label"> Mass Name </span>
                <v-text-field class="pt-2 text-des pr-2" v-model="editingitem.massName" outlined dense
                  hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6 lg6 pt-3>
                <span class="label">Date</span>
                <!-- <v-menu ref="menu" class="pt-2 text-des" style="font-size:14px" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="formattedDate" outlined dense hide-details v-bind="attrs" v-on="on" readonly></v-text-field>
                </template>
                <v-date-picker v-model="editDate" @input="updateDate" locale="en"></v-date-picker>
              </v-menu> -->

                <v-menu ref="menu" class="text-des pr-2" style="font-size: 14px" v-model="menu"
                  :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pt-2 text-des pr-2" v-model="formattedDate" outlined dense hide-details
                      v-bind="attrs" v-on="on" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="formattedDate" :min="nowDate" @input="updateDate" locale="en"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6 lg6  pr-0 pt-3>
                <span class="label">Start Time</span>
                <v-layout>
                  <v-select outlined dense hide-details class="pt-2 text-des pr-2" :items="hoursArray"
                    v-model="editingitem.startHours" placeholder="Hours"></v-select>
                  <v-select outlined dense hide-details class="pt-2 text-des pr-2" :items="minutesArray"
                    v-model="editingitem.startMinutes" placeholder="Minutes"></v-select>
                  <v-select outlined dense hide-details class="pt-2 text-des pr-2" :items="periodArray"
                    v-model="editingitem.startPeriod" placeholder="AM/PM"></v-select>
                </v-layout>
              </v-flex>

              <v-flex xs12 sm12  md6 lg6 pt-3 pr-2>
                <span class="label">Location</span>
                <v-text-field v-model="displayedLocation" outlined dense
                class="text-field pt-sm-2 pt-lg-2 pt-md-0 pt-2" placeholder="Enter Location"
               ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6 lg6 pr-2 pt-3>
                <span class="label">Special Events</span>
                <v-select class="pt-2 text-des" style="font-size: 14px" outlined dense :items="eventArray"
                  v-model="editingitem.holymassEvent" item-text="name" item-value="name" placeholder="Select Events"
                  multiple></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="editspecialdialog = false"><span style="color: black">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="editSpecial(editingitem._id)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editmonthlydialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT MONTHLY HOLY MASS </span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start px-2>
              <v-flex xs12 sm6 md6 lg6 pr-2 pt-3>
                <span class="label">Week</span>
                <v-select outlined dense hide-details class="pt-2 text-des" :items="numOfWeek" item-value="value"
                  item-text="name" v-model="editingitem.numOfWeek" placeholder="Select a Day"></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6 lg6 pr-2 pt-3>
                <span class="label">Day</span>
                <v-select outlined dense hide-details class="pt-2 text-des" :items="daysOfWeek" item-value="value"
                  item-text="name" v-model="editingitem.daysOfWeek" placeholder="Select a Day"></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6 lg6 pr-0 pt-3>
                <span class="label">Start Time</span>
                <v-layout>
                  <v-select outlined dense hide-details class="pt-2 text-des pr-2" :items="hoursArray"
                    v-model="editingitem.startHours" placeholder="Hours"></v-select>
                  <v-select outlined dense hide-details class="pt-2 text-des pr-2" :items="minutesArray"
                    v-model="editingitem.startMinutes" placeholder="Minutes"></v-select>
                  <v-select outlined dense hide-details class="pt-2 text-des pr-2" :items="periodArray"
                    v-model="editingitem.startPeriod" placeholder="AM/PM"></v-select>
                </v-layout>
              </v-flex>

              <v-flex xs12 sm6 md6 lg6 pr-2 pt-3>
                <span class="label"> Rite </span>
                <v-select class="pt-2 text-des" style="font-size: 14px" outlined dense :items="riteArray"
                  v-model="editingitem.rite" item-text="name" item-value="_id"></v-select>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pr-2>
                <span class="label"> Language </span>
                <v-select class="pt-2 text-des" style="font-size: 14px" outlined dense :items="languageArray"
                  item-text="languageName" item-value="_id" v-model="editingitem.language"></v-select>
              </v-flex>

              <v-flex xs12 sm12  md6 lg6  pr-2>
                <span class="label">Location</span>
                <v-text-field v-model="displayedLocation" outlined dense
                class="text-field pt-sm-2 pt-lg-2 pt-md-0 pt-2" placeholder="Enter Location"
               ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12  md6 lg6 pr-2>
                <span class="label">Special Events</span>
                <v-select class="pt-2 text-des" style="font-size: 14px" outlined dense :items="eventArray"
                  v-model="editingitem.holymassEvent" item-text="name" item-value="name" placeholder="Select Events"
                  multiple></v-select>
              </v-flex>


            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="editmonthlydialog = false"><span style="color: black">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="editMonthly(editingitem._id)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="deletedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to delete this holy mass?
            <br />
            This action
            <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img src="@/assets/images/calendar.png" width="70px" height="70px" class="dialog-icon" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn style="font-family: interBold; font-size: 13px; color: white" color="#cf3a45"
            @click="deletedialog = false">Cancel</v-btn>
          <v-btn style="font-family: interBold; font-size: 13px; color: white" color="blue darken-1"
            @click="confirmDelete">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import axios from "axios";
import AddSpecialMass from "./addSpecialMass.vue";
import AddMonthlyHolyMass from "./addMonthlyHolyMass.vue";
import AddWeeklyMass from "./addWeeklyMass.vue";
export default {
  components: {
    AddSpecialMass,
    AddMonthlyHolyMass,
    AddWeeklyMass
  },
  data() {
    return {
      isSpecialEventAfterMass: false,
      Pagelength: "",
      appLoading: false,
      formValid: false,
      rules: {
        required: (value) => !!value || 'This field is required',
      },
      page: 1,
      limit: 6,
      showSnackBar: false,
      timeout: 5000,
      search1: null,
      msg: null,
      deletedialog: false,
      dialogdate: false,
      OccasionDate: null,
      hours: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, "0")
      ),
      minutes: Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, "0")
      ),
      periods: ["AM", "PM"],
      // edit

      //selectedDay: null,
      //selectedWeek: null,

      holyMassEntries: [],
      //weeklyMassEntries:[],
      specialMassEntries: [],
      monthlyMassEntries: [],
      holymassEvent: [],
      eventArray: [],
      OccasionType: "Normal",
      menu2: false,
      menu: false,
      editDate: null,
      isAdding: false,
      expanded: true,
      currentAddType: null,

      hoursArray: Array.from({ length: 12 }, (_, i) => String(i + 1)), // ['1', '2', ..., '12']
      minutesArray: Array.from({ length: 60 }, (_, i) =>
        String(i).padStart(2, "0")
      ),
      periodArray: ["AM", "PM"],
      editingitem: {
        daysOfWeek: "",
        numOfWeek: "",
        startHours: "",
        startMinutes: "",
        startPeriod: "",
        holymassEvent: "",
        massLocation:"",
      },

      editdialog: false,
      editspecialdialog: false,
      editmonthlydialog: false,
      //time: null,
      numOfWeek: [
        { name: "First Week", value: "1" },
        { name: "Second Week", value: "2" },
        { name: "Third Week", value: "3" },
        { name: "Fourth Week", value: "4" },
        { name: "Fifth Week", value: "5" },
      ],
      selectedWeek: null,
      daysOfWeek: [
        { name: "SUN", value: "0" },
        { name: "MON", value: "1" },
        { name: "TUE", value: "2" },
        { name: "WED", value: "3" },
        { name: "THR", value: "4" },
        { name: "FRI", value: "5" },
        { name: "SAT", value: "6" },
      ],
      selectedDay: null,
      selectedDays:null,

      riteArray: [],
      languageArray: [],
      holymass: [
        {
          rite: "",
          language: "",
          location:"",
          selectedHour: "",
          selectedMinute: "",
          selectedPeriod: "",
          menu: false,
          OccasionType: "Normal",
          holymassEvent: []
        },
      ],
    };
  },
  watch: {
    search1() {
      this.search = this.search1;
      this.getEvent();
    },
    page() {
      this.getSpecialData();
    },
    selectedDay() {
      this.getData();
    },

    selectedWeek() {
      this.getMonthlyData();
    },
    daysOfWeek(newValue) {
      console.log("Days of Week:", newValue);
    },
    "editingitem.daysOfWeek"(newValue) {
      console.log("Editing Item Day Value:", newValue);
    },

    "editingitem.numOfWeek"(newValue) {
      console.log("Editing Item Week Value:", newValue);
    },

    editingitem: {
      deep: true,
      handler() {
        if (this.editingitem && this.editingitem.occasionDate) {
          this.editDate = this.formatDate(
            new Date(this.editingitem.occasionDate)
          );
        }
      },
    },
  },
  computed: {
    formattedTime() {
      return `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`;
    },
    //    formattedTime() {
    //   return this.selectedHour && this.selectedMinute && this.selectedPeriod
    //     ? `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`
    //     : ""; // Placeholder text
    // },
    // formattedTime() {
    //   return `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`;
    // },
    // formattedDate() {
    //   // Only format and return the date if one is selected
    //   return this.OccasionDate
    //     ? new Date(this.OccasionDate).toLocaleDateString()
    //     : "";
    // },
    displayedLocation: {
    get() {
      // Return an empty string if massLocation is 'Parish Hall', otherwise return the actual value
      return this.editingitem.massLocation === 'Parish Hall' ? '' : this.editingitem.massLocation;
    },
    set(value) {
      // Update the actual massLocation when the value changes
      this.editingitem.massLocation = value;
    }
  },
    formattedDate: {
      get() {
        // Format date as 'YYYY-MM-DD'
        return this.editDate ? this.formatDate(this.editDate) : "";
        
      },
      set(newValue) {
        // Convert string to Date object and update
        this.editDate = newValue;
        this.editingitem.occasionDate = this.parseDate(newValue);
      },
    },
    nowDate() {
      const now = new Date();
      return now.toISOString().substr(0, 10);
    },
  },
  mounted() {
    console.log("numOfWeek on mount:", this.numOfWeek);
    console.log("day on mount:", this.daysOfWeek);
    this.getLanguage();
    this.getRite();
    this.getData();
    this.setDefaultDay();
    this.setDefaultWeek();
    //this.getWeeklyData();
    this.getSpecialData();
    this.getMonthlyData();
    this.getEvent();
  },
  methods: {
    formatDate1(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB").replace(/\//g, "-");
    },

    // winStepper(item) {
    //   if (item.ref == 'addSpecial') {
    //     this.getSpecialData = item.getSpecialData
    //     this.getSpecialData();
    //     console.log("haiii",item)
    //   }
    //   if (item.ref == 'addMonthly') {
    //     this.getMonthlyData = item.getMonthlyData
    //   }
    // },
    // date
    // updateDate(OccasionDate) {
    //   this.OccasionDate = OccasionDate;
    //   this.dialogdate = false; // Close the dialog after selecting the date
    // },

    winStepper(item) {
      if (item.ref === "addWeekly") {
         this.getData = item.getData;
        this.getData();
      }
      if (item.ref === "addSpecial") {
        // this.getSpecialData = item.getSpecialData;
        this.getSpecialData();
      }
      if (item.ref === "addMonthly") {
        // this.getSpecialData = item.getSpecialData;
        this.getMonthlyData();
      }
    },
    handleDataAdded(item) {
      console.log("Received dataAdded event", item);
      if (item.getData) {
        console.log("Calling getData()");
        this.getData();
      }
      if (item.getSpecialData) {
        //console.log("Calling getSpecialData()");
        this.getSpecialData();
      }
      if (item.getMonthlyData) {
        // console.log("Calling getMonthlyData()");
        this.getMonthlyData();
      }
    },

    addEntry() {
      this.holymass.push({
        rite: "",
        language: "",
        location:"",
        selectedHour: "",
        selectedMinute: "",
        selectedPeriod: "",
        menu: false,
        formattedTime: "",
        OccasionType: "Normal",
        holymassEvent: []
      });
    },
    removeEntry(index) {
      this.holymass.splice(index, 1);
    },

    convertTo24HourFormat(hour, minute, period) {
      let convertedHour = parseInt(hour, 10);

      // Convert 12 AM to 00 (midnight) in 24-hour format
      if (period === "AM" && convertedHour === 12) {
        convertedHour = 0;
      }
      // Convert 12-hour PM time to 24-hour format (except 12 PM)
      else if (period === "PM" && convertedHour !== 12) {
        convertedHour += 12; // Convert to 24-hour format for PM times
      }

      return `${convertedHour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    },

    updateFormattedTime(entry) {
      // This sets the formatted time to display in 12-hour format with AM/PM
      entry.formattedTime = `${entry.selectedHour}:${entry.selectedMinute} ${entry.selectedPeriod}`;
      console.log(entry.formattedTime);
    },

    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getEvent() {
      this.appLoading = true;
      axios({
        url: "/get/holymass/events",
        method: "GET",

        headers: {
          token: localStorage.getItem("token"),
        },
        params:{
          keyword: this.search,
        }
      })
        .then((response) => {
          this.appLoading = false;
          this.eventArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
    
      axios({
        url: "/get/regular/holymasses",

        method: "GET",
        params: {
          id: this.$route.query.id,
          dayOfWeek: this.selectedDay,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
        // params: {
        //   //  count: this.count,
        //   // page: this.currentPage,
        //   rite: this.rite,
        //   language: this.language,
        //   keyword: this.keyword,
        //   telecastType: this.selectedServicesNames,
        //   fromDate: this.fromDate,
        //   toDate:this.toDate,

        // },
      })
        .then((response) => {
       
          this.holyMassEntries = response.data.data;
          this.isAdding = false;
          this.expanded = true;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
        })
        .catch((err) => { 
         
          this.ServerError = true;
          console.log(err);
        });
    },

 

    getSpecialData() {
      console.log("Fetching special mass data...");
      this.appLoading = true;
      axios({
        url: "/get/special/holymasses",
        method: "GET",
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: this.limit,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.specialMassEntries = response.data.data;
          this.Pagelength = Math.ceil(response.data.totalLength / this.limit);
          this.expanded = true;
          this.isAdding = false;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // getSpecialData() {
    //   this.appLoading = true;
    //   axios({
    //     url: "/get/special/holymasses",

    //     method: "GET",
    //     params: {
    //       id: this.$route.query.id,
    //       limit: this.limit,
    //       page: this.currentPage,
    //       //dayOfWeek: this.selectedDay,
    //     },
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },

    //     // params: {
    //     //   //  count: this.count,
    //     //   // page: this.currentPage,
    //     //   rite: this.rite,
    //     //   language: this.language,
    //     //   keyword: this.keyword,
    //     //   telecastType: this.selectedServicesNames,
    //     //   fromDate: this.fromDate,
    //     //   toDate:this.toDate,

    //     // },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.specialMassEntries = response.data.data;
    //       // this.pages = response.data.pages;
    //       // this.totalData = response.data.count;
    //       this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       // console.log(this.numOfWeek.map(day => day.value)); // Print only values to check uniqueness

    //       // console.log(this.daysOfWeek.map(day => day.value)); // Print only values to check uniqueness

    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    getMonthlyData() {
      this.appLoading = true;
      axios({
        url: "/get/monthlyspecial/holymasses",

        method: "GET",
        params: {
          id: this.$route.query.id,
          weekOfMonth: this.selectedWeek,
          // limit: this.limit,
          // page: this.currentPage,
          //dayOfWeek: this.selectedDay,
        },
        headers: {
          token: localStorage.getItem("token"),
        },

        // params: {
        //   //  count: this.count,
        //   // page: this.currentPage,
        //   rite: this.rite,
        //   language: this.language,
        //   keyword: this.keyword,
        //   telecastType: this.selectedServicesNames,
        //   fromDate: this.fromDate,
        //   toDate:this.toDate,

        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.monthlyMassEntries = response.data.data;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          this.isAdding = false;
          this.expanded = true;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //  convertUTCToLocal (utcDateString) {
    //     const utcDate = new Date(utcDateString);

    //     // Convert to local time using toLocaleString
    //     const localTimeString = utcDate.toLocaleString('en-US', {
    //       hour: 'numeric',
    //       minute: 'numeric',
    //       hour12: true
    //     });

    //     return localTimeString;
    //   },
    // Example conversion in your method

    //     editSlider(mass) {
    //     this.editingitem = { ...mass };  // Ensure editingitem is correctly populated
    //     this.editingitem.daysOfWeek = String(this.editingitem.daysOfWeek); // Convert to string if needed
    //     this.editdialog = true;
    //     console.log('Editing Item:', this.editingitem);
    // },
    // edit() {
    //     this.appLoading = true;

    //     // Prepare the user object for API call
    //     const user = {
    //         id: this.editingitem._id,
    //         parish: this.editingitem.parish,
    //         dayOfWeek: this.editingitem.daysOfWeek,
    //         rite: this.editingitem.rite,
    //         language: this.editingitem.language,
    //         startTime: this.editingitem.startTime // Handle conversion if needed
    //     };

    //     // Make the API call
    //     axios({
    //         url: "/edit/holymass",
    //         method: "POST",
    //         data: user,
    //         headers: {
    //             token: localStorage.getItem("token"),
    //         },
    //     })
    //     .then((response) => {
    //         this.appLoading = false;
    //         if (response.data.status) {
    //             this.editdialog = false;
    //             this.msg = "Edited Successfully";
    //             this.showsnackbar = true;
    //             this.getData();
    //         } else {
    //             this.msg = response.data.msg;
    //             this.showsnackbar = true;
    //         }
    //     })
    //     .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //     });
    // },

    updateDate(date) {
      this.menu = false; // Close the menu after selecting the date
      this.editDate = date;
      this.editingitem.occasionDate = this.formatDate(date); // Use formatDate to format the date as 'YYYY-MM-DD'
    },

    formatDate(date) {
      if (!date) return "";
      // Ensure date is formatted to 'YYYY-MM-DD'
      return date instanceof Date ? date.toISOString().slice(0, 10) : date;
    },
    parseDate(dateString) {
      // Convert 'YYYY-MM-DD' format to a Date object
      return new Date(dateString).toISOString();
    },
    editSlider(mass) {
      this.editingitem = { ...mass };

      // Parse the startTime and set it in the format needed
      const { hours, minutes, period } = this.parseTime(
        this.editingitem.startTime
      );
      this.editingitem.daysOfWeek = String(this.editingitem.daysOfWeek); // Convert to string if needed
      this.editingitem.startHours = String(hours); // Convert to string if hoursArray contains strings
      this.editingitem.startMinutes = String(minutes).padStart(2, "0");
      this.editingitem.startPeriod = period;

      this.editdialog = true;
      console.log("Editing Item:", this.editingitem);
    },

    parseTime(isoString) {
      // Extract the time part from the string without creating a Date object
      const timePart = isoString.split("T")[1].split("Z")[0];
      let [hours, minutes] = timePart.split(":");

      hours = parseInt(hours);
      const period = hours >= 12 ? "PM" : "AM";

      // Convert 24-hour format to 12-hour format
      hours = hours % 12 || 12;

      return { hours, minutes: parseInt(minutes), period };
    },
    edit() {
      this.appLoading = true;

      const hours = parseInt(this.editingitem.startHours, 10);
      const minutes = parseInt(this.editingitem.startMinutes, 10);
      const period = this.editingitem.startPeriod;

      const startTime24 =
        period === "PM" && hours < 12
          ? hours + 12
          : period === "AM" && hours === 12
            ? 0
            : hours;
      const formattedTime = `${String(startTime24).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:00`;

      const user = {
        id: this.editingitem._id,
        //parish: this.editingitem.parish,

        dayOfWeek: this.editingitem.daysOfWeek,
        // rite: this.editingitem.rite._id,
        // language: this.editingitem.language._id,
        rite: this.editingitem.rite,
        location: this.editingitem.massLocation,
        holymassEvent: this.editingitem.holymassEvent,
        language: this.editingitem.language,
        startTime: `2000-11-07T${formattedTime}.000Z`, // Adjust date as necessary
        OccasionType: "Normal",
      };

      axios({
        url: "/edit/holymass",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // edit special

    editSliderSpecial(mass) {
      this.editingitem = { ...mass };

      // Parse the startTime and set it in the format needed
      const { hours, minutes, period } = this.parseTime(
        this.editingitem.startTime
      );
      //this.editingitem.daysOfWeek = String(this.editingitem.daysOfWeek); // Convert to string if needed
      this.editingitem.startHours = String(hours); // Convert to string if hoursArray contains strings
      this.editingitem.startMinutes = String(minutes).padStart(2, "0");
      this.editingitem.startPeriod = period;

      this.editspecialdialog = true;
      console.log("Editing Item:", this.editingitem);
    },

    editSpecial() {
      this.appLoading = true;

      const hours = parseInt(this.editingitem.startHours, 10);
      const minutes = parseInt(this.editingitem.startMinutes, 10);
      const period = this.editingitem.startPeriod;

      const startTime24 =
        period === "PM" && hours < 12
          ? hours + 12
          : period === "AM" && hours === 12
            ? 0
            : hours;
      const formattedTime = `${String(startTime24).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:00`;

      const user = {
        id: this.editingitem._id,
        //parish: this.editingitem.parish,

        // dayOfWeek: this.editingitem.daysOfWeek,
        rite: this.editingitem.rite,
        language: this.editingitem.language,
        location: this.editingitem.massLocation,
        holymassEvent: this.editingitem.holymassEvent,
        startTime: `2000-11-07T${formattedTime}.000Z`, // Adjust date as necessary
        OccasionType: "Special",
        OccasionDate: this.editingitem.occasionDate,
        massName: this.editingitem.massName,
      };

      axios({
        url: "/edit/holymass",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editspecialdialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getSpecialData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editSliderMonthly(mass) {
      console.log("Mass Object:", mass); // Log the entire mass object to inspect its structure

      // Check if these properties exist in the mass object
      if (mass.recurringMonthly) {
        this.editingitem = { ...mass };

        // Parse the startTime and set it in the format needed
        const { hours, minutes, period } = this.parseTime(
          this.editingitem.startTime
        );
        this.editingitem.daysOfWeek = String(mass.recurringMonthly.dayOfWeek); // Correctly access dayOfWeek
        this.editingitem.numOfWeek = String(mass.recurringMonthly.weekOfMonth); // Correctly access weekOfMonth
        this.editingitem.startHours = String(hours); // Convert to string if hoursArray contains strings
        this.editingitem.startMinutes = String(minutes).padStart(2, "0");
        this.editingitem.startPeriod = period;

        this.editmonthlydialog = true;
        console.log("Editing Item:", this.editingitem);
      } else {
        console.error("recurringMonthly is not defined in the mass object");
      }
    },

    editMonthly() {
      this.appLoading = true;

      const hours = parseInt(this.editingitem.startHours, 10);
      const minutes = parseInt(this.editingitem.startMinutes, 10);
      const period = this.editingitem.startPeriod;

      const startTime24 =
        period === "PM" && hours < 12
          ? hours + 12
          : period === "AM" && hours === 12
            ? 0
            : hours;
      const formattedTime = `${String(startTime24).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:00`;

      const user = {
        id: this.editingitem._id,
        //parish: this.editingitem.parish,

        //dayOfWeek: this.editingitem.daysOfWeek,
        recurringMonthly: {
          weekOfMonth: this.editingitem.numOfWeek, // Use the value from editingitem
          dayOfWeek: this.editingitem.daysOfWeek, // Use the value from editingitem
        },
        // rite: this.editingitem.rite._id,
        // language: this.editingitem.language._id,
        rite: this.editingitem.rite,
        language: this.editingitem.language,
        location: this.editingitem.massLocation,
        holymassEvent: this.editingitem.holymassEvent,
        startTime: `2000-11-07T${formattedTime}.000Z`, // Adjust date as necessary
        OccasionType: "MonthlySpecial",
      };

      axios({
        url: "/edit/holymass",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editmonthlydialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getMonthlyData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submitForm() {
      if (this.$refs.holyMassForm.validate()) {
        this.itemadd(); // Your existing method for adding Holy Mass
      } else {
        this.msg = "Please fill out all required fields";
        this.showSnackBar = true;
      }
    },

    itemadd() {
      const data = {
        // if(this.holyrite || this.ghh){
        //   dayOfWeek:this.holyrite
        // }
        // assuming you have parishId defined in your data
        parish: this.$route.query.id,
        dayOfWeek: this.selectedDays,

        holymass: this.holymass.map((entry) => ({
          rite: entry.rite,
          language: entry.language,
          location:entry.location,
          // OccasionType: entry.OccasionType,
          OccasionType: "Normal",
          // startTime: entry.formattedTime, // Combine time
          startTime: this.convertTo24HourFormat(
            entry.selectedHour,
            entry.selectedMinute,
            entry.selectedPeriod
          ),
          holymassEvent: entry.holymassEvent
          // Include OccasionType if applicable
        })),
      };

      axios({
        url: "/add/multiple/holymasses",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
         
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.isAdding = !this.isAdding;
            if (!this.isAdding) {
              this.currentAddType = null; // Reset the currentAddType when exiting add mode
            }
            this.expanded = true;
            // Clear form fields or reset state

            this.getData();
            // window.location.reload();
            this.holymass = [
              {
                rite: "",
                language: "",
                location:"",
                selectedHour: "12",
                selectedMinute: "00",
                selectedPeriod: "AM",
                menu: false,
                OccasionType: "Normal",
                holymassEvent: []
              },
            ];
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
         
          this.ServerError = true;
          console.log(err);
        });
    },

    // formatTimes(startTime) {
    //     return `${this.convertUTCToLocal(startTime)} `;
    //   },
    formatTime(dateTime) {
      // Extract the time part from the string
      const timePart = dateTime.split("T")[1].split("Z")[0];

      // Split time into hours and minutes
      let [hours, minutes] = timePart.split(":");

      // Convert hours to a number
      hours = parseInt(hours);

      // Determine AM/PM
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert 24-hour time to 12-hour time
      hours = hours % 12;
      hours = hours ? hours : 12; // Adjust 0 to 12

      // Combine hours, minutes, and AM/PM
      const formattedTime = `${hours}:${minutes} ${ampm}`;

      return formattedTime;
    },

    toggleExpand() {
      this.expanded = !this.expanded;
    },
    toggleAddMode() {
      this.isAdding = !this.isAdding;
      if (!this.isAdding) {
        this.currentAddType = null; // Reset the currentAddType when exiting add mode
        this.expanded = true;
      }
    },
    startAdd(type) {
      this.currentAddType = type;
      this.isAdding = true;
      this.expanded = false; // Collapse details view when adding
    },
    setDefaultDay() {
      // Find the first day with holyMassEntries
      const daysWithMass = this.holyMassEntries.map((mass) => mass.dayOfWeek);
      const defaultDay = this.daysOfWeek.find((day) =>
        daysWithMass.includes(day.value)
      );

      // Set the first available day with holyMassEntries or default to the first day in the list
      this.selectedDay = defaultDay
        ? defaultDay.value
        : this.daysOfWeek[0].value;
    },
    handleDayClick(day) {
      this.selectedDay = day;
      // Logic for filtering holyMassEntries based on the selected day
    },

    setDefaultWeek() {
      // Find the first day with holyMassEntries
      const daysWithMass = this.monthlyMassEntries.map(
        (mass) => mass.weekOfMonth
      );
      const defaultDay = this.numOfWeek.find((day) =>
        daysWithMass.includes(day.value)
      );

      // Set the first available day with holyMassEntries or default to the first day in the list
      this.selectedWeek = defaultDay
        ? defaultDay.value
        : this.numOfWeek[0].value;
    },
    handleWeekClick(day) {
      this.selectedWeek = day;
      // Logic for filtering holyMassEntries based on the selected day
    },
    getDayName(dayValue) {
      const daysOfWeek = [
        { name: "Sunday", value: "0" },
        { name: "Monday", value: "1" },
        { name: "Tuesday", value: "2" },
        { name: "Wednesday ", value: "3" },
        { name: "Thursday", value: "4" },
        { name: "Friday", value: "5" },
        { name: "Saturday", value: "6" },
      ];
      const day = daysOfWeek.find((d) => d.value === dayValue.toString());
      return day ? day.name : "";
    },
    openDeleteDialog(mass) {
      this.itemToDelete = mass;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },

    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/delete/holymass",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
            this.getSpecialData();
            this.getMonthlyData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // saveMass() {
    //   if (this.newMass.title) {
    //     this.holymass.push({ title: this.newMass.title });
    //     this.newMass.title = ''; // Clear the form
    //     this.isAdding = false; // Exit add mode
    //   }
    // }
  },
};
</script>

<style scoped>
.time-picker-card {
  max-width: 280px;
  /* Adjust card width */
}

.time-picker-card-text {
  padding: 8px;
  /* Adjust padding inside the card */
}

.time-picker-select {
  width: 100%;
  /* Ensure dropdowns fill column width */
}

.time-picker-card-actions {
  padding: 8px;
  /* Adjust padding in the actions area */
}

.v-menu__content {
  z-index: 1000;
  /* Ensure the menu appears on top */
}

.v-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.selected-btn {
  background-color: #37a0f4 !important;
  /* Change to desired background color */
  color: #ffffff;
  /* Change to desired text color */
}

.specialmass-card {
  font-family: interregular;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #e5f5ff;
  /* Match chip color */
  height: 100%;
  /* Ensure card fills its container */
  cursor: pointer;
  /* Indicate interactivity */
}

.monthlymass-card {
  font-family: interregular;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #e5f5ff;
  /* Match chip color */
  height: 100%;
  /* Ensure card fills its container */
  cursor: pointer;
  /* Indicate interactivity */
}

.mass-card {
  font-family: interregular;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #e5f5ff;
  /* Match chip color */

  /* Ensure card fills its container */
  cursor: pointer;
  /* Indicate interactivity */
}

.card-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.text-field {
  font-family: interregular;
  font-size: 14px;
  color: black;
}
</style>