<template>
  <div>
    <vue-element-loading
    :active="appLoading"
      spinner="bar-fade-scale"
      color="#4480C5"
      size="60"
      is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl11>
        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3">
          <!-- <v-flex
            xs12
            sm12
            md12
            lg12
            text-start
            align-center
            pt-2
            class="headingChurch"
          >
            Church Details
          </v-flex> -->

          <v-flex xs12 sm3 md3 lg10 text-start align-self-center pt-2 class="headingChurch"> Church Details</v-flex>
          <v-flex xs12 sm9 md9 lg2 text-end align-self-center  v-if="userRole == 'admin'"> <v-btn
            v-if="churchView.status === 'Pending'"  
            color="blue darken-5"
            style="color:aliceblue"
            @click="openApproveDialog()"
            
          >
            Approve
          </v-btn></v-flex>
        </v-layout>
        <!-- Card Section -->
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md12 lg12>
            <!-- Card Actions (Edit and Delete) -->
            <v-card flat>
              <v-card-text style="border-radius: 8px">
                <v-layout wrap justify-start>
                  <v-flex v-if="churchView.churchImage" xs12  sm2 md2 lg1 pb-3 pb-xl-0 pb-lg-0 pb-md-0 pb-sm-0 text-center>
                    <!-- {{ churchView.churchImage[0] }} -->
                    <v-img
                      :src="mediaURL + churchView.churchImage[0]"
                      style="border-radius: 7px"
                      width="100%"
                      height="100%"
                      alt="Church"
                      class="church-icon"
                    >
                    </v-img>
                  </v-flex>
                  <v-flex xs12 sm7 md8 lg9 pl-3>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span class="churchdes">
                          <!-- Check if churchView and its properties are defined -->
                          {{ churchView.name || 'Loading...' }}
                        </span>
                      </v-flex>
                      <v-flex xs12 v-if="churchView.rite && churchView.rite.name">
                        <v-chip
                          style="font-family: interregular; border-radius: 5px"
                          color="#4480C5"
                          class="mt-3"
                          text-color="white"
                        >
                          {{ churchView.rite.name }}
                        </v-chip>
                      </v-flex>
                      <v-flex xs12 pt-2 v-if="churchView.address">
                        <span style="font-family: interregular">
                          {{ churchView.address.city }},
                          {{ churchView.address.street }},
                          {{ churchView.address.state }},
                          {{ churchView.address.country }},
                          {{ churchView.address.postalCode }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm1
                    md1
                    lg2
                    px-3
                    pt-3
                    pt-lg-0
                    pt-md-0
                    pt-sm-0
                    text-right
                  >
                    <v-flex xs12>
                      <v-btn
                        class="churchbtn"
                        elevation="0"
                        color="blue"
                        style="color: white; border-radius: 5px"
                        @click="goToEditChurch"
                      >
                        Edit Details
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 pt-lg-6 pt-md-6 pt-sm-6 pt-3  v-if="userRole == 'admin'">
                      <v-btn
                        elevation="0"
                        class="churchbtn"
                        width="125px"
                        color="#F1F1F1"
                        style="color: black; border-radius: 5px"
                          @click="goToLogs"
                      >
                        Logs
                      </v-btn>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-layout wrap justify-center v-if="checkAdor">
            <v-flex xs12>
              <v-layout wrap align-center>
                <v-flex shrink>
                  <span class="live-des">Is Adoration held for the full week?</span>
                </v-flex>
                <v-flex pl-5>
                  <v-checkbox v-model="churchView.IsAdoration24x7" @click="adorCheck(churchView.IsAdoration24x7)"/>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-dialog v-model="dialog" max-width="700px" @input="dialogClosed">
            <v-card rounded="lg">
              <v-card-title>
                <span class="editbox">FULL WEEK ADORATION</span>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="closeDialog()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
          
              <v-card-text>
                <v-form>
                  <v-layout wrap justify-center px-2>
                    <v-flex xs6 pr-2>
                      <span class="label">Rite</span>
                      <v-select class="pt-2 text-des" style="font-size:14px" outlined dense
                        :items="riteArray" v-model="rite" item-text="name" item-value="_id"></v-select>
                    </v-flex>
                    <v-flex xs6 pr-2>
                      <span class="label">Language</span>
                      <v-select class="pt-2 text-des" style="font-size:14px" outlined dense
                        :items="languageArray" item-text="languageName"
                        item-value="_id" v-model="language"></v-select>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
          
              <v-divider></v-divider>
          
              <v-card-actions class="py-4 justify-end headline grey lighten-2">
                <v-btn outlined color="grey" text @click="closeDialog()"><span style="color: black;">Cancel</span></v-btn>
                <v-btn color="primary" @click="itemadd()">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          
        </v-layout>
        <v-layout wrap justify-center v-if="checkHoly">
          <v-flex xs12>
            <HolymassView />
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="checkCon">
          <v-flex xs12>
            <ConfessionView />
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="checkAdor && !churchView.IsAdoration24x7">
          <v-flex xs12>
            <AdorationView />
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap justify-center v-if="!fullWeekAdoration">
          <v-flex xs12>
            <AdorationView />
          </v-flex>
        </v-layout> -->
      </v-flex>
    </v-layout>

    <!-- pending church approve confirmation -->
    <v-dialog v-model="approvedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Approval</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to Approve Church?
            <br />
            This action <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
         
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2 py-6">
          <v-btn
            style="font-family: interregular; font-size: 13px; color: white"
            color="#cf3a45"
            @click="approvedialog = false"
            >Cancel</v-btn
          >
          <v-btn
            style="font-family: interregular; font-size: 13px; color: white"
            color="blue darken-1"
            @click="approveItem()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HolymassView from "../HolyMass/holymassView.vue";
import ConfessionView from "../Confession/confessionView.vue";
import AdorationView from "../Adoration/adorationView.vue";
import axios from "axios";

export default {
  components: {
    HolymassView,
    ConfessionView,
    AdorationView,
  },
  data() {
    return {
      
      userRole: localStorage.getItem("role"),
      showSnackBar: false,
      timeout: 5000,
      msg: "",
      appLoading: false,
      mediaUURL:false,
      language:"",
      rite:"",
      riteArray: [],
      languageArray: [],
      dialog: false,
      deleteDialog: false,
      expanded: false,
      churchView: {}, 
      daysOfWeek: ["MON", "TUE", "WED", "THR", "FRI", "SAT", "SUN"],
      selectedDay: null,
      checkCon: "",
      checkHoly: "",
      checkAdor: "",
      fullWeekAdoration: false,
      checkboxTempValue: false, // Temporary storage for checkbox state
      dialogConfirmed: false,   // Flag to check if dialog was confirmed
      itemToConfirm: null,
      approvedialog: false,
    };
  },
  mounted() {
    this.getData();
    this.getLanguage();
    this.getRite();
    if (this.$route.query.msg) {
        this.msg = this.$route.query.msg;
        this.showSnackBar = true;
        
        // Remove the message from the query parameters
        this.$router.replace({
            path: this.$route.path,
            query: { id: this.$route.query.id }, // keep the id
        });
    }
  },
  methods: {
    // adorCheck(val){
    //   if(val==false)
    // {
    //   this.deleteFullWeek(this.churchView);
    // }
    // else if(val==true)
    // {
    //   this.dialog=true
    // }
    // },
    adorCheck(val) {
      this.checkboxTempValue = val; // Store the checkbox state before opening the dialog

      if (!val) {
        this.deleteFullWeek(this.churchView);
      } else {
        this.dialogConfirmed = false; // Reset the confirmation flag
        this.dialog = true;
      }
    },
    dialogClosed(val) {
      if (!val && !this.dialogConfirmed) {
        this.churchView.IsAdoration24x7 = false; // Untick the checkbox if dialog is closed without confirmation
      }
    },
    goToEditChurch() {
      this.$router.push("/editChurch?id=" + this.$route.query.id);
    },
    goToLogs() {
      this.$router.push("/logs?id=" + this.$route.query.id);
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/church/info",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.churchView = response.data.data || {};  
          for (let i = 0; i < this.churchView.services.length; i++) {
            if (this.churchView.services[i] === "Confession") {
              this.checkCon = this.churchView.services[i];
            } else if (this.churchView.services[i] === "Holy Mass") {
              this.checkHoly = this.churchView.services[i];
            } else if (this.churchView.services[i] === "Adoration") {
              this.checkAdor = this.churchView.services[i];
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     deleteFullWeek(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/delete/fullweek/adoration",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     itemadd() {
      const data = {
      
        parish: this.$route.query.id,
        rite:this.rite,
        language:this.language
      };

      axios({
        url: "/add/fullweek/adoration",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
             this.dialogConfirmed = true;
        
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    openApproveDialog() {
       // this.itemToConfirm = item;
        this.approvedialog = true;
      },
//       confirmApprove() {
//   if (this.itemToConfirm) {
//     this.approveItem(this.itemToConfirm);
//   }
//   this.approvedialog = false;
// },
approveItem() {
  const data = {
          id: this.$route.query.id,
        };

  axios({
    url: "/subadmin/church/approve",
    method: "POST",
    data: data,
    headers: {
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      this.appLoading = false;
      if (response.data.status) {
        this.approvedialog = false;
        // this.msg = response.data.msg;
        // this.showSnackBar = true;
        this.$router.push({
                path: "/pendingChurch",
              });
        // Update status locally after approval
       
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.appLoading = false;
      this.ServerError = true;
      console.log(err);
    });
},
    closeDialog() {
      this.dialog = false;
      // If the dialog wasn't confirmed, revert the checkbox to its original state
      if (!this.dialogConfirmed) {
        this.churchView.IsAdoration24x7 = false;
      }
    },
    saveAdoration() {
      // Logic to save full week adoration data
      this.dialog = false;
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    handleDayClick(day) {
      this.selectedDay = day;
      console.log(`Clicked on ${day}`);
    },
  },
  watch: {
    dialog(val) {
      if (!val && !this.dialogConfirmed) {
        this.churchView.IsAdoration24x7 = false; // Ensure checkbox is unticked when dialog is closed
      }
    },
   
    
  }
};
</script>


<!-- <script>
import HolymassView from "../HolyMass/holymassView.vue";
import ConfessionView from "../Confession/confessionView.vue";
import AdorationView from "../Adoration/adorationView.vue";
import axios from "axios";

export default {
  components: {
    HolymassView,
    ConfessionView,
    AdorationView,
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      expanded: false,
      churchView: {},  // Initialize as an empty object
      daysOfWeek: ["MON", "TUE", "WED", "THR", "FRI", "SAT", "SUN"],
      selectedDay: null,
      checkCon: "",
      checkHoly: "",
      checkAdor: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goToEditChurch() {
      this.$router.push("/editChurch?id=" + this.$route.query.id);
    },

    goToParishManagers() {
      this.$router.push("/assignParishManager?id=" + this.$route.query.id);
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/church/info",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.churchView = response.data.data || {};  // Fallback to an empty object
          for (let i = 0; i < this.churchView.services.length; i++) {
            if (this.churchView.services[i] === "Confession") {
              this.checkCon = this.churchView.services[i];
            } else if (this.churchView.services[i] === "Holy Mass") {
              this.checkHoly = this.churchView.services[i];
            } else if (this.churchView.services[i] === "Adoration") {
              this.checkAdor = this.churchView.services[i];
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    openDeleteDialog() {
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    confirmDelete() {
      console.log("Church deleted");
      this.closeDeleteDialog();
    },
    goToAddChurch() {
      this.$router.push({ name: "addChurch" });
    },
    editChurch() {
      this.$router.push({ name: "editChurch" });
    },
    saveChanges() {
      console.log("Form data:", this.form);
      this.dialog = false;
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    handleDayClick(day) {
      this.selectedDay = day;
      console.log(`Clicked on ${day}`);
    },
  },
};
</script> -->

<style scoped>
/* Add your styles here */
</style>
